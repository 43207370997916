import { DivElement } from "./HTML/DivElement";
import { SpanElement } from "./HTML/SpanElement";

export class LoadingSpinner {
    private _View: DivElement;
    public get View(): DivElement { return this._View; }

    constructor() {
        this.CreateCentreSpinner();
    }

    private CreateCentreSpinner() {
        this._View = DivElement.CreateDivWithClass('text-center');
        const spinnerBorder = DivElement.CreateDivWithClass('spinner-border');
        spinnerBorder.View.role = 'status';
        const spinnerSpan = SpanElement.CreateTextSpan('Loading...', ['visually-hidden']);

        spinnerBorder.AppendChild(spinnerSpan);
        this._View.AppendChild(spinnerBorder);
    }
}