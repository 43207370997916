export enum IconName {
    'file-earmark-plus' = 'file-earmark-plus',
    'eraser-fill' = 'eraser-fill',
    'arrow-counterclockwise' = 'arrow-counterclockwise',
    'floppy-fill' = 'floppy-fill',
    'x-lg' = 'x-lg',
    'at' = 'at',
    'envelope-plus' = 'envelope-plus',
    'list-ul' = 'list-ul',
    'type-bold' = 'type-bold',
    'pencil-fill' = 'pencil-fill'
}