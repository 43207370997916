import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class ImageElement extends ElementBase<HTMLImageElement> {
    private constructor(src: string, alt: string, classes: string[], attributes: IKeyStringPair[], id: string) {
        super('img', classes, attributes);

        if (id != null)
            this._View.id = id;

        this._View.src = src;
        if (alt != null)
            this._View.alt = alt;
    }

    public static CreateImageNoClass(src: string, alt: string): ImageElement {
        return new ImageElement(src, alt, undefined, undefined, undefined);
    }

    public static CreateImage(src: string, alt: string, classes: string[], attributes: IKeyStringPair[], id: string): ImageElement {
        return new ImageElement(src, alt, classes, attributes, id);
    }

    public static CreateImageWithClass(src: string, alt: string, className: string): ImageElement {
        return new ImageElement(src, alt, [className], undefined, undefined);
    }
}