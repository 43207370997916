import { IRequestContentModel } from "../../Models/IRequestContentModel";
import { ImageSizeSet } from "../../Models/ImageSourceSet";
import { IEntityIDService } from "../../Services/EntityIDService";
import { AvailableContainerEditAction } from "../../Toolbars/ContainerEditingToolbar";

export interface IContentEditor {
    Save(): Promise<void>;
    SetToEditable(): void;
    SetToReadOnly(): void;
    RevertToLastSaved(): void;
    GetRequestObject(): IRequestContentModel;
    UpdateLastSaved(): void;
    View: HTMLDivElement;
    PageID: number;
}

export interface IContentEditor_RepeaterItem {
    SetToEditable(): void;
    SetToReadOnly(): void;
    RevertToLastSaved(): void;
    GetRequestObject(): IRequestContentModel;
    UpdateLastSaved(): void;
    View: HTMLDivElement;
    EntityIDService: IEntityIDService;
}

export type RepeaterObserver = IContentDeleteObserver | IContentDropObserver;

export interface IContentDeleteObserver {
    ImplementsIContentDeleteObserver: true;
    UpdateForDelete(editor: IContentEditor_RepeaterItem): void;
}

export function IsIContentDeleteObserver(obj: any): obj is IContentDeleteObserver {
    return obj.ImplementsIContentDeleteObserver === true;
}

export interface IContentDropObserver {
    ImplementsIContentDropObserver: true;
    UpdateForDrop(editor: IContentEditor_RepeaterItem, droppedEntityID: number, droppedTemporaryID: string): void;
}

export function IsIContentDropObserver(obj: any): obj is IContentDropObserver {
    return obj.ImplementsIContentDropObserver === true;
}

export interface IExistingEditorInitialiseProperties {
    ContentContainer: HTMLDivElement;
}

export interface IImageSimpleEditorInitialiseProperties {
    Actions: Array<AvailableContainerEditAction>;
    PageID: number;
}

export interface ISimpleEditorInitialiseProperties {
    Actions: Array<AvailableContainerEditAction>;
    PageID: number;
}

export interface IImageSimpleRepeaterEditorInitialiseProperties {
    Actions: Array<AvailableContainerEditAction>;
    EntityIDService: IEntityIDService;
    ImageSizeSet: ImageSizeSet;
    AreListGroupsFlush: boolean;
    PageID: number;
}

export interface ISimpleRepeaterEditorInitialiseProperties {
    Actions: Array<AvailableContainerEditAction>;
    EntityIDService: IEntityIDService;
    AreListGroupsFlush: boolean;
    PageID: number;
}

export interface IRepeaterInitialiseProperties {
    PageID: number;
}