import { ServiceContainer } from "../../ServiceContainer";
import { ModalElement } from "../../Utilities/Modal";
import { IWebService } from "../../WebService/IWebService";
import { IRequestContentModel } from "../Models/IRequestContentModel";

export class StaticContentService<T extends IRequestContentModel> {
    private _EntityType: string;
    private _EntityCategory: string;
    private _WebService: IWebService;
    constructor(entityType: string, entityCategory: string) {
        this._EntityType = entityType;
        this._EntityCategory = entityCategory;
        this._WebService = ServiceContainer.Instance.WebService;
    }

    public Save(contentItem: T): Promise<any> {
        return this._WebService.PostRequest('/API/StaticContent/' + this._EntityType + '/Save' + this._EntityCategory + 'Async', contentItem.GetRequestObject())
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error saving the content. Please try again.');
            });
    }
}