import Toast from "bootstrap/js/dist/toast";


export class LocalToast {
    private static _Instance: LocalToast;
    private readonly _SaveContentToast: Toast;

    public static get Instance(): LocalToast {
        if (!this._Instance)
            this._Instance = new LocalToast();

        return this._Instance;
    }

    private constructor() {
        this._SaveContentToast = Toast.getOrCreateInstance(document.getElementById('ContentSaveToast'));
    }

    public ShowSaveContentToast() {
        this._SaveContentToast.show();
    }
}