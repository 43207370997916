import { IsIDestroyable } from "../../../Utilities/IDestroyable";
import { IsIObservable } from "../../../Utilities/IObservable";
import { LocalToast } from "../../../Utilities/Toast";
import { IRequestContentModel, IsIRepeaterRequestContentModel } from "../../Models/IRequestContentModel";
import { ImageSizeSet } from "../../Models/ImageSourceSet";
import { RepeatedContentModel } from "../../Models/RepeatedContentModel";
import { EntityIDService } from "../../Services/EntityIDService";
import { StaticContentService } from "../../Services/StaticContentService";
import { AvailableContainerEditAction, ContainerEditingToolbar, IContainerEditingAddObserver, IContainerEditingRevertObserver, IContainerEditingSaveObserver } from "../../Toolbars/ContainerEditingToolbar";
import { ContentContainerCreator, EditorType, RepeaterInitialiseProperties } from "../ContentContainerCreator";
import { IContentDeleteObserver, IContentDropObserver, IContentEditor, IContentEditor_RepeaterItem, IExistingEditorInitialiseProperties } from "./IContentEditor";

class RepeaterResult {
    private _DeletedNum: number;
    public get DeletedNum(): number { return this._DeletedNum; }
    public set DeletedNum(value: number) { this._DeletedNum = value; }

    private _UpdatedNum: number;
    public get UpdatedNum(): number { return this._UpdatedNum; }
    public set UpdatedNum(value: number) { this._UpdatedNum = value; }

    private _InsertedIDs: Array<{ key: string, value: number }>;

    public get InsertedIDs(): Array<{ key: string, value: number }> { return this._InsertedIDs; }
    public set InsertedIDs(arr: Array<{ key: string, value: number }>) { this._InsertedIDs = arr; }

    private _InsertedIDMap: Map<string, number>
    public get InsertedIDMap(): Map<string, number> { return this._InsertedIDMap; }
    public set InsertedIDMap(value: Map<string, number>) { this._InsertedIDMap = value; }

    public static DeserialiseObject(obj: any): RepeaterResult {
        const returnObj: RepeaterResult = Object.assign(new RepeaterResult(), obj);
        returnObj.InsertedIDMap = new Map<string, number>();
        for (const insertedID of returnObj.InsertedIDs)
            returnObj.InsertedIDMap.set(insertedID.key, insertedID.value);

        return returnObj;
    }

}

export class RepeaterContentEditor implements IContentEditor, IContentDeleteObserver, IContainerEditingAddObserver, IContainerEditingSaveObserver, IContainerEditingRevertObserver, IContentDropObserver {
    public get ImplementsIContainerEditingAddObserver(): true { return true; }
    public get ImplementsIContainerEditingRevertObserver(): true { return true; }
    public get ImplementsIContainerEditingSaveObserver(): true { return true; }
    public get ImplementsIContentDeleteObserver(): true { return true; }
    public get ImplementsIContentDropObserver(): true { return true; }

    private _ContainerElement: HTMLDivElement;
    private _RepeatedContentType: EditorType;
    private _RepeatedEntityType: string;
    private _RepeatedEntityCategory: string;
    private _RepeaterContainer: HTMLDivElement;
    private _ButtonContainer: HTMLDivElement;
    private _RepeatedClassesMap: Map<string, string[]>;
    private _RepeatedImageSizeSet: ImageSizeSet;
    private _RepeatedListGroupsAreFlush: boolean;

    private _LastSavedContentEditors: Array<IContentEditor_RepeaterItem>;
    private _AddedOrUpdatedContent: Array<IContentEditor_RepeaterItem>;
    private _DeletedContent: Set<number>;
    private _AddedEditors: Set<IContentEditor_RepeaterItem>;

    private _RepeaterEditingToolbar: ContainerEditingToolbar;
    private _RepeaterContentService: StaticContentService<RepeatedContentModel>;

    public get View(): HTMLDivElement { return this._ContainerElement; }

    private _IsEditing: boolean;
    private _PageID: number;
    public get PageID(): number { return this._PageID; }

    constructor(containerElement: HTMLDivElement, pageID: number) {
        this._ContainerElement = containerElement;
        this._RepeaterContainer = containerElement.getElementsByClassName('RepeaterContentGroup')[0] as HTMLDivElement;
        this._RepeatedContentType = EditorType[containerElement.getAttribute('data-RepeatedContentType')];
        this._RepeatedEntityType = containerElement.getAttribute('data-EntityType');
        this._RepeatedEntityCategory = containerElement.getAttribute('data-EntityCategory');
        this._RepeatedImageSizeSet = ImageSizeSet[containerElement.getAttribute('data-ImageSizeSet')];
        this._RepeatedListGroupsAreFlush = containerElement.getAttribute('data-ListGroupsFlush').toLowerCase() == 'true';
        this._ButtonContainer = containerElement.getElementsByClassName('EditingButtons')[0] as HTMLDivElement;

        containerElement.removeAttribute('data-ContentType');
        containerElement.removeAttribute('data-EntityType');
        containerElement.removeAttribute('data-EntityCategory');
        containerElement.removeAttribute('data-ListGroupsFlush');

        this._RepeatedClassesMap = new Map<string, string[]>();
        for (const attr of containerElement.attributes) {
            if (attr.name.substring(0, 13) != 'data-classes_')
                continue;

            this._RepeatedClassesMap.set(attr.name.replace('data-classes_', ''), attr.value.split(' '));
        }

        for (const attrName of this._RepeatedClassesMap.keys())
            containerElement.removeAttribute('data_classes_' + attrName);

        this._LastSavedContentEditors = new Array<IContentEditor_RepeaterItem>();
        for (const contentItem of containerElement.getElementsByClassName('RepeatedContentContainer')) {
            const entityIDService = new EntityIDService(this._RepeatedEntityType, this._RepeatedEntityCategory, parseInt(contentItem.getAttribute('data-EntityID')));
            let initialiseProperties: RepeaterInitialiseProperties<EditorType> & IExistingEditorInitialiseProperties;
            if (this._RepeatedContentType == EditorType.ImageSimpleContent)
                initialiseProperties = {
                    Actions: [AvailableContainerEditAction.Delete],
                    EntityIDService: entityIDService,
                    ImageSizeSet: this._RepeatedImageSizeSet,
                    ContentContainer: contentItem as HTMLDivElement
                };
            else if (this._RepeatedContentType == EditorType.SimpleStaticContent)
                initialiseProperties = {
                    Actions: [AvailableContainerEditAction.Delete],
                    EntityIDService: entityIDService,
                    ContentContainer: contentItem as HTMLDivElement
                }

            const editor = ContentContainerCreator.Instance.CreateRepeaterEditorForContentType(this._RepeatedContentType, initialiseProperties); 
            this._LastSavedContentEditors.push(editor);
            if (IsIObservable<IContentDeleteObserver>(editor))
                editor.Subscribe(this);

            containerElement.removeAttribute('data-EntityID');
        }

        this._AddedOrUpdatedContent = Object.assign(new Array<IContentEditor_RepeaterItem>(), this._LastSavedContentEditors);

        this.BuildEditingToolbar();
        this._RepeaterContentService = new StaticContentService<RepeatedContentModel>(this._RepeatedEntityType, this._RepeatedEntityCategory);
        this._AddedEditors = new Set<IContentEditor_RepeaterItem>();
        this._DeletedContent = new Set<number>();
        this._IsEditing = false;
        this._PageID = pageID;

        this.AddEventHandlers();
    }

    public Save(): Promise<void> {
        return this._RepeaterContentService.Save(this.GetRequestObject())
            .then((response) => {
                if (response == undefined || response < 1)
                    return;

                const result = RepeaterResult.DeserialiseObject(response);

                if (this._DeletedContent.size != result.DeletedNum || this._AddedEditors.size != result.InsertedIDMap.size || this._AddedOrUpdatedContent.length != result.InsertedIDMap.size + result.UpdatedNum)
                    Response.redirect('.');

                for (const added of this._AddedEditors) {
                    added.EntityIDService.EntityID = result.InsertedIDMap.get(added.EntityIDService.TemporaryID);
                    added.EntityIDService.TemporaryID = null;
                }

                this.UpdateLastSaved();     
                LocalToast.Instance.ShowSaveContentToast();
            });
    }

    public SetToEditable(): void {
        this._ButtonContainer.classList.add('EditModeOn');
        for (const editor of this._AddedOrUpdatedContent) {
            editor.SetToEditable();
            editor.View.draggable = true;
        }

        this._IsEditing = true;
    }
    public SetToReadOnly(): void {
        this._ButtonContainer.classList.remove('EditModeOn');
        for (const editor of this._AddedOrUpdatedContent) {
            editor.SetToReadOnly();
            editor.View.draggable = false;
        }

        this._IsEditing = false;
    }

    public UpdateForAdd(): void {
        const entityIDService = new EntityIDService(this._RepeatedEntityType, this._RepeatedEntityCategory, -1);
        let initialiseProperties: RepeaterInitialiseProperties<EditorType>;
        if (this._RepeatedContentType == EditorType.ImageSimpleContent)
            initialiseProperties = {
                Actions: [AvailableContainerEditAction.Delete],
                EntityIDService: entityIDService,
                ImageSizeSet: this._RepeatedImageSizeSet,
                AreListGroupsFlush: this._RepeatedListGroupsAreFlush,
                PageID: this._PageID
            };
        else if (this._RepeatedContentType == EditorType.SimpleStaticContent)
            initialiseProperties = {
                Actions: [AvailableContainerEditAction.Delete],
                EntityIDService: entityIDService,
                AreListGroupsFlush: this._RepeatedListGroupsAreFlush,
                PageID: this._PageID
            };

        const editor = ContentContainerCreator.Instance.BuildNewContentForRepeater(this._RepeatedContentType, this._IsEditing, this._RepeatedClassesMap, initialiseProperties);
        this._RepeaterContainer.appendChild(editor.View);
        editor.View.setAttribute('data-TemporaryID', entityIDService.TemporaryID);

        if (this._IsEditing)
            editor.View.draggable = true;

        this._AddedEditors.add(editor);
        this._AddedOrUpdatedContent.push(editor);
        if (IsIObservable<IContentDeleteObserver>(editor))
            editor.Subscribe(this);
    }

    public UpdateForDelete(editor: IContentEditor_RepeaterItem): void {
        if (IsIObservable<IContentDeleteObserver>(editor))
            editor.Unsubscribe(this);

        if (editor.EntityIDService.HasEntityID) {
            this._DeletedContent.add(editor.EntityIDService.EntityID);
            this._AddedOrUpdatedContent.splice(this._AddedOrUpdatedContent.indexOf(editor), 1);
        }
        else {
            this._AddedEditors.delete(editor);
        }

        if (IsIDestroyable(editor))
            editor.Destroy();
        else
            editor.View.remove();
    }

    public UpdateForRevert(): void {
        this.RevertToLastSaved();
    }

    public UpdateForSave(): void {
        this.Save();
    }

    public UpdateForDrop(editor: IContentEditor_RepeaterItem, droppedEntityID: number, droppedTemporaryID: string) {
        const indexToRemove = this._AddedOrUpdatedContent.findIndex(c => c.EntityIDService.MatchesByIDs(droppedEntityID, droppedTemporaryID));
        const editorToInsert = this._AddedOrUpdatedContent[indexToRemove];
        const indexToReplace = this._AddedOrUpdatedContent.indexOf(editor);

        if (indexToRemove == indexToReplace)
            return;

        editorToInsert.View.remove();

        if (indexToRemove > indexToReplace) {
            editor.View.before(editorToInsert.View);
            this._AddedOrUpdatedContent.splice(indexToRemove, 1);
            this._AddedOrUpdatedContent.splice(indexToReplace, 0, editorToInsert);
        }
        else {
            editor.View.after(editorToInsert.View);
            this._AddedOrUpdatedContent.splice(indexToReplace + 1, 0, editorToInsert);
            this._AddedOrUpdatedContent.splice(indexToRemove, 1);
        }
    }

    public RevertToLastSaved(): void {
        this._AddedEditors.forEach(e => {
            if (IsIObservable<IContentDeleteObserver>(e))
                e.Unsubscribe(this);

            if (IsIDestroyable(e))
                e.Destroy();
            else
                e.View.remove();
        });

        $(this._RepeaterContainer).children().remove();
        for (const editor of this._LastSavedContentEditors)
            this._RepeaterContainer.appendChild(editor.View);

        this._DeletedContent = new Set<number>();
        this._AddedEditors = new Set<IContentEditor_RepeaterItem>();
        this._AddedOrUpdatedContent = Object.assign(new Array<IContentEditor_RepeaterItem>(), this._LastSavedContentEditors);
    }

    public GetRequestObject(): RepeatedContentModel {
        const addedOrUpdatedContent = new Array<IRequestContentModel>();
        for (let i = 0; i < this._AddedOrUpdatedContent.length; i++) {
            const currentContent = this._AddedOrUpdatedContent[i];
            const request = currentContent.GetRequestObject();
            if (IsIRepeaterRequestContentModel(request))
                request.SortOrder = i;
            addedOrUpdatedContent.push(request);
        }
        return new RepeatedContentModel(this._PageID, addedOrUpdatedContent, Array.from(this._DeletedContent));
    }

    public UpdateLastSaved(): void {
        this._LastSavedContentEditors = Object.assign(new Array<IContentEditor_RepeaterItem>(), this._AddedOrUpdatedContent);
        for (const editor of this._AddedOrUpdatedContent)
            editor.UpdateLastSaved();

        this._AddedEditors = new Set<IContentEditor_RepeaterItem>();
        this._DeletedContent = new Set<number>();
    }

    private BuildEditingToolbar() {
        this._RepeaterEditingToolbar = new ContainerEditingToolbar();
        this._RepeaterEditingToolbar.BuildView([AvailableContainerEditAction.Add, AvailableContainerEditAction.Save, AvailableContainerEditAction.Revert]);
        this._RepeaterEditingToolbar.Subscribe(this);
        this._ButtonContainer.appendChild(this._RepeaterEditingToolbar.View);
    }

    private OnViewDragEnter = (event: DragEvent) => {
        event.preventDefault();
    }

    private OnViewDragOver = (event: DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }

    private AddEventHandlers() {
        this._ContainerElement.addEventListener('dragenter', this.OnViewDragEnter);
        this._ContainerElement.addEventListener('dragover', this.OnViewDragOver);
    }
}