import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class HeadingElement extends ElementBase<HTMLHeadingElement> {
    private constructor(headingNum: number, title: string, classes: string[], id: string, attributes: IKeyStringPair[]) {
        super('h' + headingNum, classes, attributes);
        this._View.innerText = title;
        this._View.className = (classes != null ? classes.join(' ') : '');
        if(id != null)
            this._View.id = id;
    }

    public static CreateH1(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(1, title, classes, id, undefined);
    }

    public static CreateH2(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(2, title, classes, id, undefined);
    }

    public static CreateH3(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(3, title, classes, id, undefined);
    }

    public static CreateH4(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(4, title, classes, id, undefined);
    }

    public static CreateH5(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(5, title, classes, id, undefined);
    }

    public static CreateH6(title: string, classes: string[], id: string): HeadingElement {
        return new HeadingElement(6, title, classes, id, undefined);
    }
}