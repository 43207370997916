import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class SelectElement extends ElementBase<HTMLSelectElement> {
    private _Options: Map<string, HTMLOptionElement>;

    public get Value(): string { return this._View.value; }

    private constructor(classes: string[], attributes: IKeyStringPair[], id: string, options: IKeyStringPair[]) {
        super('select', classes, attributes);

        if (id != null)
            this._View.id = id;

        this._Options = new Map<string, HTMLOptionElement>();
        for (const option of options) {
            const optionElement: HTMLOptionElement = document.createElement('option');
            optionElement.value = option.Key;
            optionElement.text = option.Value;
            this._Options.set(option.Key, optionElement);
            this._View.appendChild(optionElement);
        }
    }

    public static CreateSelect(id: string, classes: string[], options: IKeyStringPair[]): SelectElement {
        return new SelectElement(classes, undefined, id, options);
    }

    public AddChangeEventHandler(handler: JQuery.TypeEventHandler<HTMLElement, undefined, HTMLElement, HTMLElement, 'change'>) {
        this.$View.off('change', handler).on('change', handler);
    }
} 