import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class AnchorElement extends ElementBase<HTMLAnchorElement> {

    public get OpensInNewWindow(): boolean { return (this._View.target == '_blank'); }
    public set OpensInNewWindow(value: boolean) { this._View.target = (value ? '_blank' : ''); }

    public get Text(): string { return this._View.innerText; }
    public set Text(value: string) { this._View.innerText = value; }

    private constructor(link: string, text: string, classes: string[], attributes: IKeyStringPair[]) {
        super('a', classes, attributes);
        this._View.href = link;
        if (text != null)
            this._View.innerText = text;
    }

    public static CreateNewWindowAnchorForText(link: string, text: string, classes: string[]): AnchorElement {
        return new AnchorElement(link, text, classes, [{ Key: 'target', Value: '_blank' }]);
    }

    public CreateNewWindowEmptyAnchor(link: string, classes: string[]): AnchorElement {
        return new AnchorElement(link, undefined, classes, [{ Key: 'target', Value: '_blank' }]);
    }

    public static CreateAnchorForText(link: string, text: string, classes: string[]): AnchorElement {
        return new AnchorElement(link, text, classes, undefined);
    }

    public static CreateEmptyAnchor(link: string, classes: string[]): AnchorElement {
        return new AnchorElement(link, undefined, classes, undefined);
    }
}