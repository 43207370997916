import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";
import { IconElement } from "./IconElement";
import { IconName } from "./IconName";

export class ButtonElement extends ElementBase<HTMLButtonElement> {
    private constructor(classes: string[], attributes: IKeyStringPair[], text: string, iconClass: IconName) {
        super('button', classes, attributes);
        this._View.setAttribute('type', 'button');

        if (iconClass != null) {
            const icon = IconElement.CreateNewIcon(iconClass);
            this._View.appendChild(icon.View);
        }

        if (text != null)
            this._View.innerText = text;
    }

    public static CreateButtonForText(classes: string[], attributes: IKeyStringPair[], text: string): ButtonElement {
        return new ButtonElement(classes, attributes, text, undefined);
    }

    public static CreateButtonForIcon(classes: string[], attributes: IKeyStringPair[], iconClass: IconName): ButtonElement {
        return new ButtonElement(classes, attributes, undefined, iconClass);
    }

    public static CreateEmptyButton(classes: string[], attributes: IKeyStringPair[]): ButtonElement {
        return new ButtonElement(classes, attributes, undefined, undefined);
    }

    public RemoveClickEventHandler(handler: JQuery.TypeEventHandler<HTMLElement, any, any, any, 'click'>) {
        this.$View.off('click', handler);
    }

    public AddClickEventHandler<T>(handler: JQuery.TypeEventHandler<HTMLElement, T, HTMLElement, HTMLElement, 'click'>, data: T = undefined) {
        this.$View.off('click', handler).on('click', data, handler);
    }

    public Remove(): void {
        this._View.remove();
    }

    public Disable(): void {
        this._View.disabled = true;
    }

    public Enable(): void {
        this._View.disabled = false;
    }
}