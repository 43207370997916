import { SimpleContentModel, SimpleContentModel_RepeaterItem } from "../../Models/SimpleContentModel";
import { IContentDeleteObserver, IContentDropObserver, IContentEditor, IContentEditor_RepeaterItem, IsIContentDeleteObserver, IsIContentDropObserver, RepeaterObserver } from "./IContentEditor";
import { HTMLPropertyEditor } from "../PropertyContainers/HTMLPropertyEditor";
import { StaticContentService } from "../../Services/StaticContentService";
import { AvailableContainerEditAction, ContainerEditingToolbar, IContainerEditingDeleteObserver, IContainerEditingRevertObserver, IContainerEditingSaveObserver } from "../../Toolbars/ContainerEditingToolbar";
import { IObservable } from "../../../Utilities/IObservable";
import { IDestroyable } from "../../../Utilities/IDestroyable";
import { DivElement } from "../../../Utilities/HTML/DivElement";
import { HeadingElement } from "../../../Utilities/HTML/HeadingElement";
import { EntityIDService, IEntityIDService } from "../../Services/EntityIDService";
import { LocalToast } from "../../../Utilities/Toast";
export abstract class SimpleContentEditorBase {
    protected _ContainerElement: HTMLDivElement;
    private _ButtonElement: HTMLDivElement;
    private _HeadingElement: HTMLHeadingElement;
    private _BodyContainer: HTMLPropertyEditor;
    protected _EditingToolbar: ContainerEditingToolbar;

    private _LastSavedHeading: string;
    public get View(): HTMLDivElement { return this._ContainerElement; }

    protected _EntityIDService: IEntityIDService;
    protected _PageID: number;
    public get PageID(): number { return this._PageID; }

    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, pageID: number) {
        this._ContainerElement = containerElement;
        this._ButtonElement = containerElement.getElementsByClassName('EditingButtons')[0] as HTMLDivElement;
        this._HeadingElement = containerElement.getElementsByClassName('Title')[0] as HTMLHeadingElement;
        this._EntityIDService = entityIDService;
        this._BodyContainer = new HTMLPropertyEditor(containerElement.getElementsByClassName('BodyContainer')[0] as HTMLDivElement, this._EntityIDService.IDPrefixFromType + 'Body');
        this._PageID = pageID;

        this._LastSavedHeading = this._HeadingElement.innerText;
        this.BuildEditingToolbar(actions);
    }

    public SetToEditable(): void {
        this._ButtonElement.classList.add('EditModeOn');
        this._HeadingElement.setAttribute('contenteditable', 'true');
        this._BodyContainer.SetToEditable();
    }

    public SetToReadOnly(): void {
        this._ButtonElement.classList.remove('EditModeOn');
        this._HeadingElement.setAttribute('contenteditable', 'false');
        this._BodyContainer.SetToReadOnly();
    }

    public RevertToLastSaved(): void {
        this._HeadingElement.innerText = this._LastSavedHeading;
        this._BodyContainer.RevertToLastSaved();
    }

    public GetRequestObject(): SimpleContentModel {
        return new SimpleContentModel(this._HeadingElement.innerText, this._BodyContainer.CurrentHTMLContent.PlainContent, this._BodyContainer.CurrentHTMLContent.ContentWithTokens, this._PageID);
    }

    public UpdateLastSaved(): void {
        this._LastSavedHeading = this._HeadingElement.innerText;
        this._BodyContainer.UpdateLastSaved();
    }

    protected BuildEditingToolbar(actions: Array<AvailableContainerEditAction>): void {
        this._EditingToolbar = new ContainerEditingToolbar();
        this._EditingToolbar.BuildView(actions);

        this._ButtonElement.appendChild(this._EditingToolbar.View);
    }
}

export class SimpleContentEditor extends SimpleContentEditorBase implements IContentEditor, IContainerEditingSaveObserver, IContainerEditingRevertObserver {
    public get ImplementsIContainerEditingSaveObserver(): true { return true; }
    public get ImplementsIContainerEditingRevertObserver(): true { return true; }
            
    private _SimpleContentService: StaticContentService<SimpleContentModel>;

    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, pageID: number) {
        super(containerElement, actions, new EntityIDService(containerElement.getAttribute('data-EntityType'), containerElement.getAttribute('data-EntityCategory'), parseInt(containerElement.getAttribute('data-EntityID'))), pageID);

        this._SimpleContentService = new StaticContentService<SimpleContentModel>(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory);

        containerElement.removeAttribute('data-EntityType');
        containerElement.removeAttribute('data-EntityCategory');
        containerElement.removeAttribute('data-EntityID');
    }

    public Save(): Promise<void> {
        return this._SimpleContentService.Save(this.GetRequestObject())
            .then((data) => {
                if (data == undefined || isNaN(data) || Number.parseInt(data) < 1)
                    return;

                this.UpdateLastSaved();
                LocalToast.Instance.ShowSaveContentToast();
            })
            .catch(() => {
                console.error('Could not save.');
            });
    }

    public UpdateForSave(): void {
        this.Save();
    }

    public UpdateForRevert(): void {
        this.RevertToLastSaved();
    }

    protected override BuildEditingToolbar(actions: Array<AvailableContainerEditAction>): void {
        super.BuildEditingToolbar(actions);

        this._EditingToolbar.Subscribe(this);
    }
}

export class SimpleContentEditor_RepeaterItem extends SimpleContentEditorBase implements IContentEditor_RepeaterItem, IContainerEditingDeleteObserver, IObservable<RepeaterObserver>, IDestroyable {
    public get ImplementsIContainerEditingDeleteObserver(): true { return true; }
    public ImplementsIObservable<RepeaterObserver>(): true { return true; }
    public get ImplementsIDestroyable(): true { return true; }

    private _DeleteObservers: Set<IContentDeleteObserver>;
    private _DropObservers: Set<IContentDropObserver>;

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }
    public get EntityIDService(): IEntityIDService { return this._EntityIDService; }

    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, pageID: number) {
        super(containerElement, actions, entityIDService, pageID);

        this._DeleteObservers = new Set<IContentDeleteObserver>();
        this._DropObservers = new Set<IContentDropObserver>();
        this._IsDestroyed = false;

        this.AddEventHandlers();
    }

    public Subscribe(observer: RepeaterObserver): void {
        if (IsIContentDeleteObserver(observer))
            this._DeleteObservers.add(observer);

        if (IsIContentDropObserver(observer))
            this._DropObservers.add(observer);
    }

    public Unsubscribe(observer: RepeaterObserver): void {
        if (IsIContentDeleteObserver(observer))
            this._DeleteObservers.delete(observer);

        if (IsIContentDropObserver(observer))
            this._DropObservers.delete(observer);
    }

    public UpdateForDelete(): void {
        for (const observer of this._DeleteObservers)
            observer.UpdateForDelete(this);
    }

    public Destroy(): void {
        this._EditingToolbar.Destroy();
        this._EditingToolbar = null;

        $(this._ContainerElement).empty();
        this._ContainerElement.remove();
        this._ContainerElement = null;
        this._DeleteObservers = null;

        this._IsDestroyed = true;
    }

    public static BuildNew(actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, isEditing: boolean, repeatedClassMap: Map<string, string[]>, areListGroupsFlush: boolean, pageID: number): SimpleContentEditor_RepeaterItem {
        const container = DivElement.CreateDiv(['RepeatedContentContainer', 'position-relative', ...repeatedClassMap.get('content')], [
            { Key: 'data-EntityID', Value: '-1' }
        ], undefined, undefined);

        const editingButtons = DivElement.CreateDivWithClasses(['EditingButtons', 'position-absolute', 'start-0', 'bg-white', 'rounded', 'col-auto', 'p-0']);
        const heading = HeadingElement.CreateH2('New Title', ['text-primary-emphasis', 'Title', 'Header'], undefined);
        heading.View.setAttribute('contenteditable', (isEditing ? 'true' : 'false'));
        const bodyContainer = DivElement.CreateDivWithClasses(['BodyContainer', ...repeatedClassMap.get('body')]);
        const paragraph = document.createElement('p');
        paragraph.className = 'Body';
        paragraph.setAttribute('contenteditable', (isEditing ? 'true' : 'false'));
        paragraph.textContent = 'New Content';
        paragraph.setAttribute('data-ListGroupsFlush', (areListGroupsFlush ? 'true' : 'false'));
        bodyContainer.View.appendChild(paragraph);

        container.Append(editingButtons, heading, bodyContainer);
        const editor = new SimpleContentEditor_RepeaterItem(container.View, actions, entityIDService, pageID);
        if (isEditing)
            editor.SetToEditable();
        else
            editor.SetToReadOnly();

        return editor;
    }

    protected override BuildEditingToolbar(actions: Array<AvailableContainerEditAction>): void {
        super.BuildEditingToolbar(actions);

        this._EditingToolbar.Subscribe(this);
    }

    public override GetRequestObject(): SimpleContentModel_RepeaterItem {
        const request = super.GetRequestObject();
        return new SimpleContentModel_RepeaterItem(request.Heading, request.BodyPlain, request.BodyWithTokens, this._EntityIDService.EntityID, this._EntityIDService.TemporaryID, this._PageID);
    }

    private OnViewDragStartEvent = (event: DragEvent) => {
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setData('text/json', JSON.stringify({ EntityID: this._EntityIDService.EntityID, TempID: this._EntityIDService.TemporaryID }));
    }

    private OnViewDropEvent = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const data = JSON.parse(event.dataTransfer.getData('text/json'));

        let droppedEntityID: number = data['EntityID'];
        let droppedTemporaryID: string = data['TemporaryID'];

        for (let observer of this._DropObservers)
            observer.UpdateForDrop(this, droppedEntityID, droppedTemporaryID);
    }

    private AddEventHandlers(): void {
        this._ContainerElement.addEventListener('dragstart', this.OnViewDragStartEvent);
        this._ContainerElement.addEventListener('drop', this.OnViewDropEvent);
    }
}