export interface IEntityIDService {
    EntityType: string;
    EntityCategory: string;
    EntityID: number;
    TemporaryID: string;
    HasEntityID: boolean;
    MatchesByIDs(entityID: number, tempID: string): boolean;
    IDPrefixFromType: string;
}

export class EntityIDService implements IEntityIDService {
    private _EntityType: string;
    public get EntityType(): string { return this._EntityType; }

    private _EntityCategory: string;
    public get EntityCategory(): string { return this._EntityCategory; }

    private _EntityID: number;
    public get EntityID(): number { return this._EntityID; }
    public set EntityID(value: number) { this._EntityID = value; }
    private _TemporaryID: string;
    public get TemporaryID(): string { return this._TemporaryID; }
    public set TemporaryID(value: string) { this._TemporaryID = value; }

    public get HasEntityID(): boolean { return this._EntityID != -1 && this._EntityID != null; }

    public get IDPrefixFromType(): string { return this._EntityType + (this._EntityCategory ?? ""); }

    constructor(entityType: string, entityCategory: string, entityID: number) {
        this._EntityType = entityType;
        this._EntityCategory = entityCategory;
        this._EntityID = entityID;
        if (this._EntityID == -1)
            this._TemporaryID = window.crypto.randomUUID();
    }

    public MatchesByIDs(entityID: number, tempID: string): boolean {
        if (this.HasEntityID && entityID == this._EntityID)
            return true;

        if (!this.HasEntityID && tempID == this._TemporaryID && tempID != null)
            return true;

        return false;
    }
}