import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class SpanElement extends ElementBase<HTMLSpanElement> {
    private constructor(classes: string[], attributes: IKeyStringPair[], id: string, text: string) {
        super('span', classes, attributes);
        if (id != null)
            this._View.id = id;

        if (text != null)
            this._View.innerText = text;
    }

    public static CreateSpan(classes: string[], attributes: IKeyStringPair[], id: string, text: string): SpanElement {
        return new SpanElement(classes, attributes, id, text);
    }

    public static CreateSpanWithAttributes(attributes: IKeyStringPair[], text: string): SpanElement {
        return new SpanElement(undefined, attributes, undefined, text);
    }

    public static CreateSpanWithClasses(classes: string[]): SpanElement {
        return new SpanElement(classes, undefined, undefined, undefined);
    }

    public static CreateTextSpan(text: string, classes: string[]): SpanElement {
        return new SpanElement(classes, undefined, undefined, text);
    }
}