import { HTMLContentTokeniser } from "../../Services/HTMLContentTokeniser";
import { IContentViewer } from "./IContentViewer";

export class ContentViewer implements IContentViewer {
    private _ContentContainer: HTMLDivElement;
    constructor(contentContainer: HTMLDivElement) {
        this._ContentContainer = contentContainer;
    }

    public PrepareContentForView(): void {
        this._ContentContainer.querySelectorAll('[data-ContentWithTokens]').forEach((e) => {
            e.replaceChildren(...HTMLContentTokeniser.GetDisplayNodesFromTokenisedString(e.getAttribute('data-ContentWithTokens'), (e.getAttribute('data-ListGroupsFlush').toLowerCase() === 'true')));
            e.removeAttribute('data-ContentWithTokens');
            e.removeAttribute('data-ListGroupsFlush');
        });
    }
}