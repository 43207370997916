import { ButtonElement } from "../../Utilities/HTML/ButtonElement";
import { DivElement } from "../../Utilities/HTML/DivElement";
import { IconName } from "../../Utilities/HTML/IconName";
import { InputElement } from "../../Utilities/HTML/InputElement";
import { LabelElement } from "../../Utilities/HTML/LabelElement";
import { IObservable } from "../../Utilities/IObservable";
import { ModalElement } from "../../Utilities/Modal";
import { ISelectionRangeBookmarker } from "../Services/SelectionRangeBookmarker";

export interface IContentPropertyEditingObserver {
    UpdateForUrlLink(url: string, text: string, isButton: boolean, newWindow: boolean): void;
    UpdateForEmailLink(email: string, text: string, isButton: boolean): void;
    UpdateForList(): void;
    UpdateForBold(): void;
}

export class ContentPropertyEditingToolbar implements IObservable<IContentPropertyEditingObserver>, IAddLinkModalObserver {
    public ImplementsIObservable<IContentPropertyEditingObserver>(): true { return true; }

    private _AddURLLinkButton: ButtonElement;
    private _AddEmailButton: ButtonElement;
    private _AddListButton: ButtonElement;
    private _AddBoldButton: ButtonElement;
    private _View: HTMLMenuElement;

    public get View(): HTMLMenuElement { return this._View; }

    private _Observers: Set<IContentPropertyEditingObserver>;

    private _URLLinkModal: AddLinkModal;
    private _EmailLinkModal: AddLinkModal;

    private _IDPrefix: string;

    private _SelectionRangeBookmarker: ISelectionRangeBookmarker;

    constructor(idPrefix: string, selectionRangeBookmarker: ISelectionRangeBookmarker) {
        this._Observers = new Set<IContentPropertyEditingObserver>();
        this._IDPrefix = idPrefix;
        this._SelectionRangeBookmarker = selectionRangeBookmarker;
    }

    public BuildView(): HTMLMenuElement {
        this._View = document.createElement('menu');
        this._View.classList.add(...['BodyButtons', 'position-absolute', 'BodyButtonsPosition', 'start-0', 'btn-group', 'bg-white', 'p-0', 'rounded', 'm-0']);

        this._AddURLLinkButton = ButtonElement.CreateButtonForIcon(['btn', 'btn-outline-primary'], [{ Key: 'aria-label', Value: 'New URL Link' }], IconName.at);
        this._View.appendChild(document.createElement('li').appendChild(this._AddURLLinkButton.View));

        this._AddEmailButton = ButtonElement.CreateButtonForIcon(['btn', 'btn-outline-primary'], [{ Key: 'aria-label', Value: 'New Email Link' }], IconName["envelope-plus"]);
        this._View.appendChild(document.createElement('li').appendChild(this._AddEmailButton.View));

        this._AddListButton = ButtonElement.CreateButtonForIcon(['btn', 'btn-outline-primary'], [{ Key: 'aria-label', Value: 'New List' }], IconName["list-ul"]);
        this._View.appendChild(document.createElement('li').appendChild(this._AddListButton.View));

        this._AddBoldButton = ButtonElement.CreateButtonForIcon(['btn', 'btn-outline-primary'], [{ Key: 'aria-label', Value: 'New Bold' }], IconName["type-bold"]);
        //this._View.appendChild(document.createElement('li').appendChild(this._AddBoldButton.View)); ///TODO: Fix bold button

        this.AddEventHandlers();
        return this._View;
    }

    public Subscribe(observer: IContentPropertyEditingObserver): void {
        this._Observers.add(observer);
    }

    public Unsubscribe(observer: IContentPropertyEditingObserver): void {
        this._Observers.delete(observer);
    }

    public UpdateForSave(link: string, text: string, isButton: boolean, type: AddLinkType, newWindow: boolean): void {
        for (const observer of this._Observers) {
            if (type == 'URL') {
                observer.UpdateForUrlLink(link, text, isButton, newWindow);
            }
            else if (type == 'Email') {
                observer.UpdateForEmailLink(link, text, isButton);
            }
        }
    }

    public UpdateForModalClose(): void {
        this._SelectionRangeBookmarker.ClearCurrentRange();
    }

    public SetToEditable(): void {
        this._View.classList.add('EditModeOn');
    }

    public SetToReadOnly(): void {
        this._View.classList.remove('EditModeOn');
    }

    private AddEventHandlers(): void {
        this._AddURLLinkButton.AddClickEventHandler(this.OnAddURLLinkButtonClick);
        this._AddEmailButton.AddClickEventHandler(this.OnAddEmailButtonClick);
        this._AddListButton.AddClickEventHandler(this.OnAddListButtonClick);
        this._AddBoldButton.AddClickEventHandler(this.OnAddBoldButtonClick);
    }

    private OnAddURLLinkButtonClick = () => {
        this._SelectionRangeBookmarker.SetCurrentRange();
        if (this._URLLinkModal == null) {
            this._URLLinkModal = new AddLinkModal(this._IDPrefix + 'URLLink', 'URL');
            this._URLLinkModal.BuildAndAttach();
            this._URLLinkModal.Subscribe(this);
        }
        else {
            this._URLLinkModal.ClearValues();
        }
        this._URLLinkModal.Show();
    }

    private OnAddEmailButtonClick = () => {
        this._SelectionRangeBookmarker.SetCurrentRange();
        if (this._EmailLinkModal == null) {
            this._EmailLinkModal = new AddLinkModal(this._IDPrefix + 'EmailLink', 'Email');
            this._EmailLinkModal.BuildAndAttach();
            this._EmailLinkModal.Subscribe(this);
        }
        else {
            this._EmailLinkModal.ClearValues();
        }
        this._EmailLinkModal.Show();
    }

    private OnAddListButtonClick = () => {
        this._SelectionRangeBookmarker.SetCurrentRange();
        for (const observer of this._Observers)
            observer.UpdateForList();
    }

    private OnAddBoldButtonClick = () => {
        this._SelectionRangeBookmarker.SetCurrentRange();
        for (const observer of this._Observers)
            observer.UpdateForBold();
    }
}


type AddLinkType = 'URL' | 'Email';

interface IAddLinkModalObserver {
    UpdateForSave(link: string, text: string, isButton: boolean, type: AddLinkType, newWindow: boolean): void;
    UpdateForModalClose(): void;
}

class AddLinkModal extends ModalElement implements IObservable<IAddLinkModalObserver> {
    public ImplementsIObservable<IAddLinkModalObserver>(): true { return true; }

    private _Link: InputElement;
    private _Text: InputElement;
    private _IsButtonCheckbox: InputElement;
    private _OpenNewWindowCheckbox: InputElement;
    private _SaveButton: ButtonElement;
    private _CancelButton: ButtonElement;

    private _Observers: Set<IAddLinkModalObserver>;

    private _AddLinkType: AddLinkType;

    constructor(idPrefix: string, type: AddLinkType) {
        super(idPrefix, (type == 'URL' ? 'Add Link to URL' : 'Add Link To Email'), false);
        this._Observers = new Set<IAddLinkModalObserver>();
        this._AddLinkType = type;
    }

    public override BuildAndAttach(): void {
        const body = DivElement.CreateDivWithClass('ModalBody');

        this._SaveButton = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Save');
        this._CancelButton = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Cancel');
        const footerButtons: ButtonElement[] = [this._SaveButton, this._CancelButton];

        super.BuildAndAttach(body, footerButtons);

        const linkID: string = this._IDPrefix + 'Link';
        this._Link = InputElement.CreateTextInput(['form-control'], undefined, linkID);
        const linkLabel = LabelElement.CreateLabel(linkID, (this._AddLinkType == 'URL' ? 'URL Link' : 'Email Link'), ['form-label']);
        const linkDiv = DivElement.CreateDivWithClasses(['row', 'align-items-center', 'mb-2']);

        const linkLabelWrapper = DivElement.CreateDivWithClass('col-4');
        linkLabelWrapper.AppendChild(linkLabel);

        const linkInputWrapper = DivElement.CreateDivWithClass('col-8');
        linkInputWrapper.AppendChild(this._Link);

        linkDiv.Append(linkLabelWrapper, linkInputWrapper);

        const textID: string = this._IDPrefix + 'DisplayText';
        this._Text = InputElement.CreateTextInput(['form-control'], undefined, textID);
        const textLabel = LabelElement.CreateLabel(textID, 'Text to display', ['form-label']);
        const textDiv = DivElement.CreateDivWithClasses(['row', 'align-items-center', 'mb-2']);

        const textLabelWrapper = DivElement.CreateDivWithClass('col-4');
        textLabelWrapper.AppendChild(textLabel);

        const textInputWrapper = DivElement.CreateDivWithClass('col-8');
        textInputWrapper.AppendChild(this._Text);

        textDiv.Append(textLabelWrapper, textInputWrapper);

        const isButtonID: string = this._IDPrefix + 'IsButton';
        this._IsButtonCheckbox = InputElement.CreateCheckboxInput(['form-check-input'], undefined, isButtonID);
        const isButtonLabel = LabelElement.CreateLabel(isButtonID, 'Display as button', ['form-check-label']);
        const isButtonDiv = DivElement.CreateDivWithClasses(['row', 'align-items-center', 'mb-2']);

        const buttonLabelWrapper = DivElement.CreateDivWithClass('col-4');
        buttonLabelWrapper.AppendChild(isButtonLabel);

        const buttonInputWrapper = DivElement.CreateDivWithClass('col-8');
        buttonInputWrapper.AppendChild(this._IsButtonCheckbox);

        isButtonDiv.Append(buttonLabelWrapper, buttonInputWrapper);

        body.Append(linkDiv, textDiv, isButtonDiv);

        if (this._AddLinkType == 'URL') {
            const newWindowID: string = this._IDPrefix + 'NewWindow';
            this._OpenNewWindowCheckbox = InputElement.CreateCheckboxInput(['form-check-input'], undefined, newWindowID);
            const newWindowLabel = LabelElement.CreateLabel(newWindowID, 'Open in new tab', ['form-check-label']);
            const newWindowDiv = DivElement.CreateDivWithClasses(['row', 'align-items-center', 'mb-2']);

            const newWindowLabelWrapper = DivElement.CreateDivWithClass('col-4');
            newWindowLabelWrapper.AppendChild(newWindowLabel);

            const newWindowInputWrapper = DivElement.CreateDivWithClass('col-8');
            newWindowInputWrapper.AppendChild(this._OpenNewWindowCheckbox);

            newWindowDiv.Append(newWindowLabelWrapper, newWindowInputWrapper);

            body.Append(newWindowDiv);
        }
    }

    public Subscribe(observer: IAddLinkModalObserver): void {
        this._Observers.add(observer);
    }

    public Unsubscribe(observer: IAddLinkModalObserver): void {
        this._Observers.delete(observer);
    }

    public ClearValues(): void {
        this._Link.ClearValue();
        this._Text.ClearValue();
        this._IsButtonCheckbox.ClearValue();
        if (this._OpenNewWindowCheckbox)
            this._OpenNewWindowCheckbox.ClearValue();
    }

    protected override AddEventHandlers(): void {
        super.AddEventHandlers();

        this._SaveButton.AddClickEventHandler(this.OnSaveButtonClick);
        this._CancelButton.AddClickEventHandler(this.OnCancelButtonClick);
    }

    private OnSaveButtonClick = () => {
        for (const observer of this._Observers)
            observer.UpdateForSave(this._Link.Value, this._Text.Value, this._IsButtonCheckbox.Checked, this._AddLinkType, (this._OpenNewWindowCheckbox ? this._OpenNewWindowCheckbox.Checked : false));
        this.Hide();
    }

    private OnCancelButtonClick = () => {
        this.Hide();
    }
}