import { IDestroyable } from "../IDestroyable";
import { IKeyStringPair } from "../IStringStringPair";
import { IconElement } from "./IconElement";

type appendableElement = ElementBase<HTMLElement> | IconElement;

export abstract class ElementBase<T extends HTMLElement> implements IDestroyable {
    public get ImplementsIDestroyable(): true { return true; }

    protected _View: T;
    public get View(): T { return this._View; }

    private _$View: JQuery;
    public get $View(): JQuery {
        if (this._$View == null)
            this._$View = $(this.View);

        return this._$View;
    }

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }

    protected constructor(elementType: string, classes: string[], attributes: IKeyStringPair[]) {
        this._IsDestroyed = false;
        this._View = document.createElement(elementType) as T;
        if (classes != null)
            this._View.className = classes.join(' ');

        if (attributes == null)
            return;

        for (let attr of attributes) {
            this._View.setAttribute(attr.Key, attr.Value);
        }
    }

    public Destroy(): void {
        if (this._$View) {
            this._$View.remove();
            this._$View = null;
        }
        this._View.remove();
        this._View = null;
        this._IsDestroyed = true;
    }

    public Append(...elements: appendableElement[]): void {
        this._View.append(...elements.map((e) => e.View));
    }

    public AppendChild(element: appendableElement): void {
        this._View.appendChild(element.View);
    }

    public Hide(): void {
        this._View.hidden = true;
    }

    public Show(): void {
        this._View.hidden = false;
    }

    public AddClass(...classNames: string[]): void {
        this._View.classList.add(...classNames);
    }

    public RemoveClass(...classNames: string[]): void {
        this._View.classList.remove(...classNames);
    }
}