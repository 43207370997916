import { AnchorElement } from "../../Utilities/HTML/AnchorElement";

export class HTMLContentTokeniser {
    private static GetInnerTokenisedString(node: ChildNode): string {
        let returnString = '';

        if (node.nodeType == Node.TEXT_NODE) {
            returnString += node.textContent;
        }
        else if (node.nodeType == node.ELEMENT_NODE) {
            const nodeAsElement = node as HTMLElement;
            switch (nodeAsElement.tagName.toLowerCase()) {
                case 'div':
                    returnString += '{{DIV;';

                    if (!nodeAsElement.hasChildNodes())
                        return returnString + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                case 'a':
                    if (!(nodeAsElement instanceof HTMLAnchorElement))
                        return returnString;

                    let token: string = '{{';
                    const isUrl: boolean = nodeAsElement.classList.contains('UrlLink');
                    if (isUrl) {
                        token += 'URL_LINK;';
                        token += nodeAsElement.href + ';';
                    }
                    else {
                        token += 'EMAIL_LINK;';
                        token += nodeAsElement.href.replace('mailto:', '') + ';';
                    }

                    token += nodeAsElement.innerText + ';';

                    if (nodeAsElement.classList.contains('btn'))
                        token += 'BUTTON';
                    else
                        token += 'INLINE';

                    if (isUrl) {
                        if (nodeAsElement.target == '_blank')
                            token += ';NEW_TAB';
                        else
                            token += ';SAME_TAB';
                    }

                    token += '}}';

                    returnString += token;
                    break;
                case 'br':
                    returnString += '{{BREAK;}}';
                    break;
                case 'ul':
                    returnString += '{{LIST;';

                    if (!nodeAsElement.hasChildNodes())
                        return returnString + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                case 'ol':
                    returnString += '{{NUM_LIST;';

                    if (!nodeAsElement.hasChildNodes())
                        return returnString + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                case 'li':
                    returnString += '{{ITEM;';

                    if (!nodeAsElement.hasChildNodes())
                        return returnString + nodeAsElement.innerText + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                case 'b':
                    returnString += '{{BOLD;';
                    if (!nodeAsElement.hasChildNodes())
                        return returnString + nodeAsElement.innerText + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                case 'span':
                    returnString += nodeAsElement.textContent;
                    break;
                case 'p':
                    returnString += '{{PARA;';
                    if (!nodeAsElement.hasChildNodes())
                        return returnString + nodeAsElement.innerText + '}}';

                    for (const child of nodeAsElement.childNodes)
                        returnString += this.GetInnerTokenisedString(child);

                    returnString += '}}';

                    break;
                default:
                    return returnString;
            }            
        }

        return returnString;
    }

    public static GetTokenisedStringFromElement(htmlElement: HTMLElement): string {
        let tokenContent: string = '';
        for (const node of htmlElement.childNodes) {
            if (node.nodeType == Node.TEXT_NODE) {
                tokenContent += node.textContent;
            }
            else if (node.nodeType == node.ELEMENT_NODE) {
                const nodeAsElement = (node as HTMLElement);
                tokenContent += this.GetInnerTokenisedString(nodeAsElement);
            }
        }
        return tokenContent;
    }

    public static GetDisplayNodesFromTokenisedString(tokenisedString: string, areListGroupsFlush: boolean): Node[] {
        const returnNodes = new Array<Node>();

        if (tokenisedString == null)
            return returnNodes;

        let editedString = tokenisedString;
        let openingIndex = editedString.indexOf('{{');

        while (openingIndex > -1) {
            if (openingIndex > 0)
                returnNodes.push(document.createTextNode(editedString.substring(0, openingIndex)));

            let tokenCopy: string = editedString.substring(openingIndex + 2);
            let currentToken: string;
            let nextOpeningIndex: number = tokenCopy.indexOf('{{');
            let closingIndex: number = tokenCopy.indexOf('}}');

            while (nextOpeningIndex != -1 && nextOpeningIndex < closingIndex) {
                tokenCopy = tokenCopy.substring(0, nextOpeningIndex) + ']]' + tokenCopy.substring(nextOpeningIndex + 2);
                tokenCopy = tokenCopy.substring(0, closingIndex) + ']]' + tokenCopy.substring(closingIndex + 2);
                nextOpeningIndex = tokenCopy.indexOf('{{');
                closingIndex = tokenCopy.indexOf('}}');
            }

            const relativeClosingIndex: number = openingIndex + closingIndex + 2;
            currentToken = editedString.substring(openingIndex + 2, relativeClosingIndex);

            editedString = editedString.substring(relativeClosingIndex + 2);
            openingIndex = editedString.indexOf('{{');

            const components: string[] = currentToken.split(';');
            if (components.length < 1) {
                returnNodes.push(document.createTextNode(currentToken));

                continue;
            }

            const type: string = components[0].toUpperCase().replace('{{', '');
            switch (type) {
                case 'DIV':
                    const divElement = document.createElement('div');
                    components.splice(0, 1);
                    divElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(divElement);
                    break;
                case 'BREAK':
                    returnNodes.push(document.createElement('br'));

                    break;
                case 'URL_LINK':
                case 'EMAIL_LINK':
                    let tabTarget = '';
                    if (type == 'URL_LINK') {
                        if (components.length != 5) {
                            returnNodes.push(document.createTextNode(currentToken));

                            continue;
                        }

                        tabTarget = components[4].replace('}}', '');
                    }

                    if (type == 'EMAIL_LINK' && components.length != 4) {
                        returnNodes.push(document.createTextNode(currentToken));

                        continue;
                    }

                    let value: string = components[1];
                    const text: string = components[2];
                    const display: string = components[3].toUpperCase().replace('}}', '');
                    let linkElement: AnchorElement;

                    const classes = new Array<string>();
                    if (type == 'EMAIL_LINK') {
                        value = 'mailto:' + value;
                        classes.push('EmailLink');
                    }
                    else {
                        classes.push('UrlLink');
                    }
                    if (display == 'BUTTON') {
                        classes.push('btn');
                        classes.push('btn-accent');
                        classes.push('text-primary');
                        classes.push('btn-lg');
                    }
                    if (tabTarget == 'NEW_TAB')
                        linkElement = AnchorElement.CreateNewWindowAnchorForText(value, text, classes);
                    else
                        linkElement = AnchorElement.CreateAnchorForText(value, text, classes);

                    returnNodes.push(linkElement.View);

                    break;
                case 'LIST':
                    const listElement = document.createElement('ul');
                    if (areListGroupsFlush) {
                        listElement.classList.add('list-group-flush')
                        listElement.classList.add('list-group');
                    }

                    components.splice(0, 1);
                    listElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(listElement);
                    break;
                case 'NUM_LIST':
                    const numListElement = document.createElement('ol');
                    
                    components.splice(0, 1);
                    numListElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(numListElement);
                    break;
                case 'ITEM':
                    const itemElement = document.createElement('li');
                    if (areListGroupsFlush)
                        itemElement.classList.add('list-group-item');
                    components.splice(0, 1);
                    itemElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(itemElement);
                    break;
                case 'BOLD':
                    const boldElement = document.createElement('b');
                    components.splice(0, 1);

                    boldElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(boldElement);
                    break;
                case 'PARA':
                    const pElement = document.createElement('p');
                    components.splice(0, 1);

                    pElement.append(...this.GetDisplayNodesFromTokenisedString(components.join(';'), areListGroupsFlush));

                    returnNodes.push(pElement);
                    break;
                default:
                    returnNodes.push(document.createTextNode(currentToken));

                    continue;
            }

            
        }

        if (editedString != null && editedString.length > 0)
            returnNodes.push(document.createTextNode(editedString));

        return returnNodes;
    }
}