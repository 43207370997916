import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class LabelElement extends ElementBase<HTMLLabelElement> {
    private constructor(forName: string, text: string, classes: string[], attributes: IKeyStringPair[]) {
        super('label', classes, attributes);
        this._View.htmlFor = forName;
        this._View.innerText = text;
    }

    public static CreateLabel(forName: string, text: string, classes: string[]): LabelElement {
        return new LabelElement(forName, text, classes, undefined);
    }

    public static CreateLabelNoClass(forName: string, text: string): LabelElement {
        return new LabelElement(forName, text, undefined, undefined);
    }
}