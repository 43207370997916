import { AnchorElement } from "../../../Utilities/HTML/AnchorElement";
import { HTMLContentTokeniser } from "../../Services/HTMLContentTokeniser";
import { ISelectionRangeBookmarker, SelectionRangeBookmarker } from "../../Services/SelectionRangeBookmarker";
import { ContentPropertyEditingToolbar, IContentPropertyEditingObserver } from "../../Toolbars/ContentPropertyEditingToolbar";
import { IComponentEditor } from "./IComponentEditor";

export interface HTMLContent {
    PlainContent: string;
    ContentWithTokens: string;
    HTMLContent: string;
}

export class HTMLPropertyEditor implements IContentPropertyEditingObserver, IComponentEditor {
    private _PropertyContainer: HTMLDivElement;
    private _PropertyElement: HTMLParagraphElement;
    private _LastSavedHTMLContent: HTMLContent;
    private _CurrentHTMLContent: HTMLContent;
    public get CurrentHTMLContent(): HTMLContent {
        this.UpdateCurrentHTMLContent();

        return this._CurrentHTMLContent;
    }

    private _PropertyToolbar: ContentPropertyEditingToolbar;
    private _SelectionRangeBookmarker: ISelectionRangeBookmarker;
    private _AreListGroupsFlush: boolean;

    constructor(propertyContainer: HTMLDivElement, idPrefix: string) {
        this._PropertyContainer = propertyContainer;
        this._PropertyElement = propertyContainer.getElementsByClassName('Body')[0] as HTMLParagraphElement;
        const plainContent: string = this._PropertyElement.getAttribute('data-PlainContent');
        const contentWithTokens: string = this._PropertyElement.getAttribute('data-ContentWithTokens');
        this._AreListGroupsFlush = (this._PropertyElement.getAttribute('data-ListGroupsFlush').toLowerCase() === 'true');

        let displayNodes: Node[] = HTMLContentTokeniser.GetDisplayNodesFromTokenisedString(contentWithTokens, this._AreListGroupsFlush);

        if (displayNodes.length == 0) {
            const pEl = document.createElement('p');
            pEl.innerText = 'Edit here';
            displayNodes.push(pEl);
        }

        this._PropertyElement.replaceChildren(...displayNodes);
        const htmlContent: string = this._PropertyElement.innerHTML;
        this._LastSavedHTMLContent = {
            PlainContent: plainContent,
            ContentWithTokens: contentWithTokens,
            HTMLContent: htmlContent
        };

        this._CurrentHTMLContent = {
            PlainContent: plainContent,
            ContentWithTokens: contentWithTokens,
            HTMLContent: htmlContent
        };

        this._PropertyElement.removeAttribute('data-PlainContent');
        this._PropertyElement.removeAttribute('data-ContentWithTokens');
        this._PropertyElement.removeAttribute('data-ListGroupsFlush');

        this._SelectionRangeBookmarker = new SelectionRangeBookmarker();
        this._PropertyToolbar = new ContentPropertyEditingToolbar(idPrefix, this._SelectionRangeBookmarker);
        this._PropertyToolbar.BuildView();
        this._PropertyToolbar.SetToReadOnly();
        this._PropertyContainer.appendChild(this._PropertyToolbar.View);
        this._PropertyContainer.classList.add('position-relative');

        this.AddEventHandlers();
    }

    public RevertToLastSaved(): void {
        this._CurrentHTMLContent = {
            PlainContent: this._LastSavedHTMLContent.PlainContent,
            ContentWithTokens: this._LastSavedHTMLContent.ContentWithTokens,
            HTMLContent: this._LastSavedHTMLContent.HTMLContent
        };

        this._PropertyElement.innerHTML = this._CurrentHTMLContent.HTMLContent;
    }

    public UpdateLastSaved(): void {
        this._LastSavedHTMLContent = {
            PlainContent: this._CurrentHTMLContent.PlainContent,
            ContentWithTokens: this._CurrentHTMLContent.ContentWithTokens,
            HTMLContent: this._CurrentHTMLContent.HTMLContent
        };
    }

    public UpdateForUrlLink(url: string, text: string, isButton: boolean, newWindow: boolean): void {
        const linkElement = AnchorElement.CreateAnchorForText(url, text, ['UrlLink']);
        linkElement.OpensInNewWindow = newWindow;

        if (isButton) 
            linkElement.View.className += ' btn btn-accent text-primary btn-lg';

        if (this._SelectionRangeBookmarker.HasRange && this._SelectionRangeBookmarker.ContainsSelection(this._PropertyElement)) {
            this._SelectionRangeBookmarker.BookmarkedRange.deleteContents();
            this._SelectionRangeBookmarker.BookmarkedRange.insertNode(linkElement.View);
        }
        else {
            this._PropertyElement.appendChild(linkElement.View);
        }
    }

    public UpdateForEmailLink(email: string, text: string, isButton: boolean): void {
        const linkElement = AnchorElement.CreateAnchorForText('mailto:' + email, text, ['EmailLink']);
        this._SelectionRangeBookmarker.BookmarkedRange.deleteContents();

        if (isButton)
            linkElement.View.className += ' btn btn-primary text-off-white';

        if (this._SelectionRangeBookmarker.HasRange && this._SelectionRangeBookmarker.ContainsSelection(this._PropertyElement)) {
            this._SelectionRangeBookmarker.BookmarkedRange.deleteContents();
            this._SelectionRangeBookmarker.BookmarkedRange.insertNode(linkElement.View);
        }
        else {
            this._PropertyElement.appendChild(linkElement.View);
        }
    }

    public UpdateForList(): void {
        const listElement: HTMLUListElement = document.createElement('ul');
        if (this._AreListGroupsFlush)
            listElement.classList.add('list-group-flush list-group');

        const listItem: HTMLLIElement = document.createElement('li');
        if (this._AreListGroupsFlush)
            listItem.classList.add('list-group-item');
        listItem.innerText = 'Item 1';
        listElement.appendChild(listItem);
        

        this._SelectionRangeBookmarker.BookmarkedRange.deleteContents();

        const startContainer = this._SelectionRangeBookmarker.BookmarkedRange.startContainer;
        if (startContainer.nodeName.toLowerCase() == 'p' && startContainer.textContent == '')
            $(startContainer).replaceWith(listElement);
        else if ($(startContainer).parents('p').length > 0)
            $(startContainer).parents('p').insertAfter(listElement);
        else
            this._SelectionRangeBookmarker.BookmarkedRange.insertNode(listElement);
    }

    public UpdateForBold(): void {
        const bElement: HTMLElement = document.createElement('b');
        bElement.innerText = 'here';

        this._SelectionRangeBookmarker.BookmarkedRange.deleteContents();
        this._SelectionRangeBookmarker.BookmarkedRange.insertNode(bElement);
    }

    public SetToEditable(): void {
        this._PropertyElement.setAttribute('contenteditable', 'true');
        this._PropertyToolbar.SetToEditable();
    }

    public SetToReadOnly(): void {
        this._PropertyElement.setAttribute('contenteditable', 'false');
        this._PropertyToolbar.SetToReadOnly();
    }

    private AddEventHandlers() {
        this._PropertyToolbar.Subscribe(this);

        this._PropertyElement.removeEventListener('input', this.PropertyInputEventHandler);
        this._PropertyElement.addEventListener('input', this.PropertyInputEventHandler);
    }

    private UpdateCurrentHTMLContent(): void {
        this._CurrentHTMLContent = {
            PlainContent: this._PropertyElement.innerText,
            HTMLContent: this._PropertyElement.innerHTML,
            ContentWithTokens: HTMLContentTokeniser.GetTokenisedStringFromElement(this._PropertyElement)
        };        
    }

    private PropertyInputEventHandler = (event: InputEvent) => {
        if (!this._AreListGroupsFlush)
            return;

        for (const elem of this._PropertyElement.getElementsByTagName('li'))
            elem.classList.add('list-group-item');
    }
}