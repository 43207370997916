import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class DivElement extends ElementBase<HTMLDivElement> {
    private constructor(classes: string[], attributes: IKeyStringPair[], id: string, tabIndex: number) {
        super('div', classes, attributes);
        if (id != null)
            this._View.id = id;
        if (tabIndex != null)
            this._View.tabIndex = tabIndex;
    }

    public static CreateDiv(classes: string[], attributes: IKeyStringPair[], id: string, tabIndex: number): DivElement {
        return new DivElement(classes, attributes, id, tabIndex);
    }

    public static CreateDivWithID(id: string): DivElement {
        return new DivElement(undefined, undefined, id, undefined);
    }

    public static CreateDivWithClasses(classes: string[]): DivElement {
        return new DivElement(classes, undefined, undefined, undefined);
    }

    public static CreateDivWithClass(className: string): DivElement {
        return new DivElement([className], undefined, undefined, undefined);
    }

    public RemoveClickEventHandler(handler: JQuery.TypeEventHandler<HTMLElement, any, any, any, 'click'>) {
        this.$View.off('click', handler);
    }

    public AddClickEventHandler<T>(handler: JQuery.TypeEventHandler<HTMLElement, T, HTMLElement, HTMLElement, 'click'>, data: T = undefined) {
        this.$View.off('click', handler).on('click', data, handler);
    }
}