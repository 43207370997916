import { IRequestContentModel } from "./IRequestContentModel";

export class RepeatedContentModel implements IRequestContentModel {
    private _PageID: number;
    private _AddedOrUpdatedItems: Array<IRequestContentModel>;
    private _DeletedItems: Array<number>;

    constructor(pageID: number, addedOrUpdatedItems: Array<IRequestContentModel>, deletedItems: Array<number>) {
        this._PageID = pageID;
        this._AddedOrUpdatedItems = addedOrUpdatedItems;
        this._DeletedItems = deletedItems;
    }

    public GetRequestObject(): object {
        return {
            "PageID": this._PageID,
            "AddedOrUpdatedItems": this._AddedOrUpdatedItems.map(i => i.GetRequestObject()),
            "DeletedItems": this._DeletedItems
        };
    }
}