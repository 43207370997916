import { IWebService } from "./IWebService";

export class FetchWebService implements IWebService {
    private readonly _XSRFToken: string;

    public constructor() {
        this._XSRFToken = $('input:hidden[name="__RequestVerificationToken"]').val() as string;
    }

    public async GetRequest(methodUrl: string): Promise<any> {
        return this.FetchRequest('GET', methodUrl, undefined);
    }

    public async PostRequest(methodUrl: string, data: object): Promise<any> {
        return this.FetchRequest('POST', methodUrl, data);
    }

    public async DeleteRequest(methodUrl: string, data: object): Promise<any> {
        return this.FetchRequest('DELETE', methodUrl, data);
    }

    public async PostFormRequest(methodUrl: string, dataForm: FormData): Promise<boolean> {
        const req: RequestInit = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'RequestVerificationToken': this._XSRFToken
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer',
            body: dataForm
        };
        const response = await fetch(methodUrl, req);
        return response.ok;
    }

    private async FetchRequest(type: string, methodUrl: string, data: object): Promise<any> {
        const req: RequestInit = {
            method: type,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'RequestVerificationToken': this._XSRFToken
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer'
        };
        if (data != undefined)
            req.body = JSON.stringify(data);

        const response = await fetch(methodUrl, req);

        if (!response.ok)
            throw new Error('Response was not valid.');

        return response.json();
    }
}