import { ButtonElement } from "../../Utilities/HTML/ButtonElement";
import { DivElement } from "../../Utilities/HTML/DivElement";
import { IconName } from "../../Utilities/HTML/IconName";
import { ImageElement } from "../../Utilities/HTML/ImageElement";
import { InputElement } from "../../Utilities/HTML/InputElement";
import { LabelElement } from "../../Utilities/HTML/LabelElement";
import { SelectElement } from "../../Utilities/HTML/SelectElement";
import { IObservable } from "../../Utilities/IObservable";
import { LoadingSpinner } from "../../Utilities/LoadingSpinner";
import { ModalElement } from "../../Utilities/Modal";
import { CMSImageWebService } from "../../WebService/CMSImageWebService";
import { CMSImageGetResult } from "../../WebService/ServiceResponses/CMSImageGetResult";
import { ISelectedImageProvider } from "../ContentContainers/PropertyContainers/SelectedImageEditor";
import { IGlobalCachedImageObserver, ISingleEntityCachedImageObserver, ImageDataStore } from "../DataStores/ImageDataStore";
import { IEntityIDService } from "../Services/EntityIDService";

export interface IImageEditingObserver {
    UpdateForSelectedImage(imagePath: string, isGlobal: boolean, imageID: number): void;
}

export class ImageEditingToolbar implements IObservable<IImageEditingObserver>, IImageSelectorObserver {
    public ImplementsIObservable<IImageEditingObserver>(): true { return true; }

    private _View: DivElement;

    public get View(): DivElement { return this._View; }

    private _Observers: Set<IImageEditingObserver>;
    private _IDPrefix: string;

    private _ImageSelector: ImageSelector;
    private _OpenSelectorButton: ButtonElement;

    private _EntityIDService: IEntityIDService;
    private _SelectedImageProvider: ISelectedImageProvider;

    constructor(idPrefix: string, entityIDService: IEntityIDService, selectedImageProvider: ISelectedImageProvider) {
        this._Observers = new Set<IImageEditingObserver>();
        this._IDPrefix = idPrefix;
        this._EntityIDService = entityIDService;
        this._SelectedImageProvider = selectedImageProvider;
    }

    public Subscribe(observer: IImageEditingObserver): void {
        this._Observers.add(observer);
    }

    public Unsubscribe(observer: IImageEditingObserver): void {
        this._Observers.delete(observer);
    }

    public BuildView(): DivElement {
        this._View = DivElement.CreateDivWithClass('ImageButtons');
        const buttonsContainer = DivElement.CreateDivWithClasses(['ImageButtonsMenu', 'position-absolute', 'top-0', 'start-0', 'end-0', 'bottom-0']);
        const menu = document.createElement('menu');
        menu.className = 'position-absolute top-50 start-50 p-0 bg-white rounded';

        const overlay = DivElement.CreateDivWithClasses(['ImageOverlay', 'bg-light', 'position-absolute', 'top-0', 'start-0', 'end-0', 'bottom-0', 'opacity-50']);
        

        this._OpenSelectorButton = ButtonElement.CreateButtonForIcon(['btn btn-outline-primary'], [{ Key: 'aria-label', Value: 'Update' }], IconName["pencil-fill"]);
        menu.appendChild(this._OpenSelectorButton.View);

        buttonsContainer.View.appendChild(menu);
        this._View.Append(buttonsContainer, overlay);

        this.AddEventHandlers();
        return this._View;
    }

    public SetToEditable(): void {
        this._View.AddClass('EditModeOn');
    }

    public SetToReadOnly(): void {
        this._View.RemoveClass('EditModeOn');
    }

    public UpdateForSelectedImage(imagePath: string, isGlobal: boolean, imageID: number): void {
        for (const observer of this._Observers)
            observer.UpdateForSelectedImage(imagePath, isGlobal, imageID);
    }

    private AddEventHandlers(): void {
        this._OpenSelectorButton.AddClickEventHandler(this.OnOpenSelectorButtonClick);
    }

    private OnOpenSelectorButtonClick = () => {
        if (this._ImageSelector == null) {
            this._ImageSelector = new ImageSelector(this._IDPrefix, this._EntityIDService, this._SelectedImageProvider);
            this._ImageSelector.BuildAndAttach();
            this._ImageSelector.Subscribe(this);
        }
        this._ImageSelector.Show();
    }
}

interface IImageSelectorObserver {
    UpdateForSelectedImage(imagePath: string, isGlobal: boolean, imageID: number): void;
}

class ImageSelector extends ModalElement implements IObservable<IImageSelectorObserver>, IGlobalCachedImageObserver, ISingleEntityCachedImageObserver {
    public get ImplementsIGlobalCachedImageObserver(): true { return true; }
    public get ImplementsISingleEntityCachedImageObserver(): true { return true; }
    public ImplementsIObservable<IImageSelectorObserver>(): true { return true; }

    private _Observers: Set<IImageSelectorObserver>;

    private _OptionsDiv: DivElement;
    private _LocationDropdown: SelectElement;
    private _UploadButton: ButtonElement;

    private _SelectedImageProvider: ISelectedImageProvider;
    private _EntityIDService: IEntityIDService;

    private _GlobalImagesContainer: DivElement;
    private _SpecificImagesContainer: DivElement;
    private _GlobalImagesLoaded: boolean;
    private _SpecificImagesLoaded: boolean;

    private _UploadContainer: DivElement;
    private _UploadShowing: boolean;
    private _UploadSaveButton: ButtonElement;
    private _UploadCancelButton: ButtonElement;
    private _FileInput: InputElement;

    private _LoadingSpinner: LoadingSpinner;
    private readonly _FilePathPrefix: string;
    private _SelectedImageElement: DivElement;

    constructor(idPrefix: string, entityIDService: IEntityIDService, selectedImageProvider: ISelectedImageProvider) {
        super(idPrefix, 'Select Image', false);
        this._Observers = new Set<IImageSelectorObserver>();
        this._EntityIDService = entityIDService
        this._SelectedImageProvider = selectedImageProvider;

        this._GlobalImagesLoaded = false;
        this._SpecificImagesLoaded = false;
        this._FilePathPrefix = '/img/CMS/';
        this._UploadShowing = false;
    }

    public override BuildAndAttach(): void {
        const body = DivElement.CreateDivWithClass('ModalBody');

        const locationID: string = this._IDPrefix + 'Location';
        this._LocationDropdown = SelectElement.CreateSelect(locationID, ['form-select'], [
            { Key: 'SPECIFIC', Value: 'Content Specific' },
            { Key: 'GLOBAL', Value: 'All Images' }
        ]);
        this._UploadButton = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Upload');
        this._UploadSaveButton = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Upload File');
        this._UploadCancelButton = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Cancel Upload');
        this._UploadSaveButton.Hide();
        this._UploadCancelButton.Hide();

        super.BuildAndAttach(body, [this._UploadSaveButton, this._UploadCancelButton]);

        const locationSelectorDiv = DivElement.CreateDivWithClasses(['col-md-9', 'row', 'align-items-center']);
        const locationLabelDiv = DivElement.CreateDivWithClass('col-auto');


        const locationLabel = LabelElement.CreateLabel(locationID, 'Image Location', ['form-label']);
        locationLabelDiv.AppendChild(locationLabel);
        const selectDiv = DivElement.CreateDivWithClass('col-auto');
        selectDiv.AppendChild(this._LocationDropdown);
        locationSelectorDiv.Append(locationLabelDiv, selectDiv);
        this._OptionsDiv = DivElement.CreateDivWithClass('row');

        const uploadButtonDiv = DivElement.CreateDivWithClass('col-md-3');
        uploadButtonDiv.AppendChild(this._UploadButton);

        this._OptionsDiv.Append(locationSelectorDiv, uploadButtonDiv);

        this._SpecificImagesContainer = DivElement.CreateDivWithClasses(['row', 'mt-3']);
        this._GlobalImagesContainer = DivElement.CreateDivWithClasses(['row', 'mt-3']);
        this._UploadContainer = DivElement.CreateDivWithClasses(['row', 'mt-3']);
        this._GlobalImagesContainer.Hide();
        this._SpecificImagesContainer.Hide();
        this._UploadContainer.Hide();

        this._LoadingSpinner = new LoadingSpinner();
        const fileID: string = this._IDPrefix + 'NewFile';
        const fileLabel = LabelElement.CreateLabel(fileID, 'Choose File', ['form-label']);
        this._FileInput = InputElement.CreateFileInput(['form-control'], undefined, fileID);

        const fileLabelWrapper = DivElement.CreateDivWithClass('col-auto');
        fileLabelWrapper.AppendChild(fileLabel);

        const fileInputWrapper = DivElement.CreateDivWithClass('col');
        fileInputWrapper.AppendChild(this._FileInput);

        this._UploadContainer.Append(fileLabelWrapper, fileInputWrapper);

        body.Append(this._OptionsDiv, this._SpecificImagesContainer, this._GlobalImagesContainer, this._UploadContainer, this._LoadingSpinner.View);
    }

    public override Show(): void {
        super.Show();
        this.GetAndShowAvailableImages();
    }

    public Subscribe(observer: IImageSelectorObserver): void {
        this._Observers.add(observer);
    }

    public Unsubscribe(observer: IImageSelectorObserver): void {
        this._Observers.delete(observer);
    }

    public UpdateForInvalidatedGlobalCache(): void {
        this._GlobalImagesLoaded = false;
    }

    public UpdateForInvalidatedEntityCache(entityType: string, entityCategory: string, entityID: number): void {
        if (entityType == this._EntityIDService.EntityType && entityCategory == this._EntityIDService.EntityCategory && this._EntityIDService.HasEntityID && entityID == this._EntityIDService.EntityID)
            this._SpecificImagesLoaded = false;
    }

    public UpdateForInvalidatedTempNewEntityCache(entityType: string, entityCategory: string, tempID: string): void {
        if (entityType == this._EntityIDService.EntityType && entityCategory == this._EntityIDService.EntityCategory && !this._EntityIDService.HasEntityID && this._EntityIDService.TemporaryID == tempID)
            this._SpecificImagesLoaded = false;
    }

    protected override AddEventHandlers(): void {
        super.AddEventHandlers();
        this._LocationDropdown.AddChangeEventHandler(this.OnLocationChange);
        this._UploadButton.AddClickEventHandler(this.OnUploadButtonClick);
        ImageDataStore.Instance.Subscribe(this);
        this._UploadCancelButton.AddClickEventHandler(this.OnUploadCancelClick);
        this._UploadSaveButton.AddClickEventHandler(this.OnUploadSaveClick);
    }

    private ShowContainerBasedOnLocation() {
        if (this._LocationDropdown.Value == 'SPECIFIC') {
            this._SpecificImagesContainer.Show();
            this._GlobalImagesContainer.Hide();
        }
        else if (this._LocationDropdown.Value == 'GLOBAL') {
            this._SpecificImagesContainer.Hide();
            this._GlobalImagesContainer.Show();
        }
    }

    private OnLocationChange = () => {
        if (!this._UploadShowing)
            this.ShowContainerBasedOnLocation();
    }

    private ShowUploadContainer() {
        this._UploadContainer.Show();
        this._UploadCancelButton.Show();
        this._UploadSaveButton.Show();
        this._GlobalImagesContainer.Hide();
        this._SpecificImagesContainer.Hide();
        this._UploadButton.Hide();
    }

    private HideUploadContainer() {
        this._UploadContainer.Hide();
        this._UploadSaveButton.Hide();
        this._UploadCancelButton.Hide();
        this._FileInput.ClearValue();
        this._UploadButton.Show();
        this._UploadShowing = false;
    }

    private OnUploadButtonClick = () => {
        this.ShowUploadContainer();
    }

    private OnUploadSaveClick = () => {
        const isGlobal: boolean = this._LocationDropdown.Value == 'GLOBAL';
        let uploadPromise: Promise<boolean>;
        if (!this._EntityIDService.HasEntityID && !isGlobal)
            uploadPromise = CMSImageWebService.Instance.UploadImageForNewEntity(this._FileInput.View.files, this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.TemporaryID);
        else
            uploadPromise = CMSImageWebService.Instance.UploadImage(this._FileInput.View.files, isGlobal, this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.EntityID)

        uploadPromise.then((success) => {
                if (success) {
                    this.HideUploadContainer();

                    if (isGlobal)
                        ImageDataStore.Instance.InvalidateForGlobal();
                    else if (this._EntityIDService.HasEntityID)
                        ImageDataStore.Instance.InvalidateForEntities(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.EntityID);
                    else
                        ImageDataStore.Instance.InvalidateForTempNewEntities(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.TemporaryID);

                    this.GetAndShowAvailableImages();
                }
        });
    }

    private OnUploadCancelClick = () => {
        this.HideUploadContainer();
        this.ShowContainerBasedOnLocation();
    }

    private GetAndShowAvailableImages(): void {
        if (!this._GlobalImagesLoaded || !this._SpecificImagesLoaded) {
            this._LoadingSpinner.View.Show();
            this._OptionsDiv.Hide();
            this._GlobalImagesContainer.Hide();
            this._SpecificImagesContainer.Hide();

            this.LoadImages().then(() => {
                this.ShowContainerBasedOnLocation();
                this._LoadingSpinner.View.Hide();
                this._OptionsDiv.Show();
            });
            return;
        }

        this.ShowContainerBasedOnLocation();
        this._LoadingSpinner.View.Hide();
        this._OptionsDiv.Show();
    }

    private LoadImages(): Promise<void> {
        let globalImagePromise: Promise<void>;
        let specificImagePromise: Promise<void>;

        if (!this._GlobalImagesLoaded) {
            this._GlobalImagesContainer.$View.empty();
            globalImagePromise = ImageDataStore.Instance.GetGlobalImagePaths()
                .then((images: CMSImageGetResult[]) => {
                    if (images == undefined || images.length == 0)
                        return;

                    for (const image of images)
                        this._GlobalImagesContainer.AppendChild(this.CreateImageForPath(image.ImagePath, true, image.ImageID));

                    this._GlobalImagesLoaded = true;
                });
        }

        if (!this._SpecificImagesLoaded) {
            this._SpecificImagesContainer.$View.empty();
            let getImagePathPromise: Promise<CMSImageGetResult[]>;
            if (!this._EntityIDService.HasEntityID)
                getImagePathPromise = ImageDataStore.Instance.GetTempImagePathsForNewEntity(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.TemporaryID);
            else
                getImagePathPromise = ImageDataStore.Instance.GetImagePathsForEntity(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.EntityID);

            specificImagePromise = getImagePathPromise
                .then((images: CMSImageGetResult[]) => {
                    if (images == undefined || images.length == 0)
                        return;

                    for (const image of images)
                        this._SpecificImagesContainer.AppendChild(this.CreateImageForPath(image.ImagePath, false, image.ImageID));

                    this._SpecificImagesLoaded = true;
                });
        }

        return Promise.all([globalImagePromise, specificImagePromise]).then();
    }

    private CreateImageForPath(path: string, isGlobal: boolean, imageID: number): DivElement {
        const imgContainer = DivElement.CreateDivWithClasses(['col-sm-12', 'col-md-4', 'position-relative', 'ImageSelectorImage']);
        const buttonContainer = DivElement.CreateDivWithClasses(['rounded', 'bg-white', 'ImageSelectorImageButtons', 'position-absolute']);
        const deleteButton = ButtonElement.CreateButtonForIcon(['btn', 'btn-outline-primary'], [{ Key: 'title', Value: 'Delete' }], IconName["eraser-fill"]);
        buttonContainer.AppendChild(deleteButton);
        let imgFullPath: string;
        if (isGlobal)
            imgFullPath = this._FilePathPrefix + 'Global/' + path;
        else if (this._EntityIDService.HasEntityID)
            imgFullPath = this._FilePathPrefix + this._EntityIDService.EntityType + '/' + this._EntityIDService.EntityCategory + '/' + this._EntityIDService.EntityID.toString() + '/' + path;
        else
            imgFullPath = this._FilePathPrefix + this._EntityIDService.EntityType + '/' + this._EntityIDService.EntityCategory + '/Temp/' + this._EntityIDService.TemporaryID + '/' + path;

        const imgWrapper = DivElement.CreateDivWithClasses(['ImageSelectorImageWrapper', 'rounded']);
        const img = ImageElement.CreateImageWithClass(imgFullPath, undefined, 'img-thumbnail');
        imgWrapper.AppendChild(img);
        imgContainer.Append(buttonContainer, imgWrapper);

        if (imageID == this._SelectedImageProvider.CurrentImageID) {
            if (this._SelectedImageElement != null)
                this._SelectedImageElement.View.classList.remove(...['border', 'border-secondary']);

            this._SelectedImageElement = imgWrapper;
            imgWrapper.View.classList.add(...['border', 'border-secondary']);
        }

        imgContainer.AddClickEventHandler<[string, boolean, DivElement, number]>(this.OnImageClick, [path, isGlobal, imgWrapper, imageID]);
        deleteButton.AddClickEventHandler<[string, boolean, DivElement, DivElement]>(this.OnDeleteImageClick, [path, isGlobal, imgContainer, imgWrapper]);

        return imgContainer;
    }

    private OnImageClick = (event: JQuery.ClickEvent<HTMLElement, [string, boolean, DivElement, number], HTMLElement, HTMLElement>) => {
        for (const observer of this._Observers)
            observer.UpdateForSelectedImage(event.data[0], event.data[1], event.data[3]);

        if (this._SelectedImageElement != null)
            this._SelectedImageElement.View.classList.remove(...['border', 'border-secondary']);

        this._SelectedImageElement = event.data[2];
        event.data[2].View.classList.add(...['border', 'border-secondary']);
    }

    private OnDeleteImageClick = (event: JQuery.ClickEvent<HTMLElement, [string, boolean, DivElement, DivElement]>) => {
        event.stopPropagation();

        const isGlobal: boolean = event.data[1];
        let deleteImagePromise: Promise<boolean>;
        if (isGlobal)
            deleteImagePromise = CMSImageWebService.Instance.DeleteGlobalImage(event.data[0]);
        else if (this._EntityIDService.HasEntityID)
            deleteImagePromise = CMSImageWebService.Instance.DeleteImageForEntity(event.data[0], this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.EntityID);
        else
            deleteImagePromise = CMSImageWebService.Instance.DeleteTempImageForNewEntity(event.data[0], this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.TemporaryID);

        deleteImagePromise
            .then((success: boolean) => {
                if (!success)
                    return;

                if (this._SelectedImageElement == event.data[3])
                    this._SelectedImageElement = null;

                event.data[2].Destroy();
                if (isGlobal)
                    ImageDataStore.Instance.InvalidateForGlobal();
                else if (this._EntityIDService.HasEntityID)
                    ImageDataStore.Instance.InvalidateForEntities(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.EntityID);
                else
                    ImageDataStore.Instance.InvalidateForTempNewEntities(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory, this._EntityIDService.TemporaryID);
            });
    }
}