import { IKeyStringPair } from "../IStringStringPair";
import { ElementBase } from "./ElementBase";

export class InputElement extends ElementBase<HTMLInputElement> {
    public get Value(): string { return this._View.value; }
    public set Value(value: string) { this._View.value = value; }
    public get Checked(): boolean { return this._View.checked; }
    private constructor(type: string, classes: string[], attributes: IKeyStringPair[], id: string) {
        super('input', classes, attributes);
        this._View.type = type;

        if (id != null)
            this._View.id = id;
    }

    public static CreateTextInput(classes: string[], attributes: IKeyStringPair[], id: string): InputElement {
        return new InputElement('text', classes, attributes, id);
    }

    public static CreateNumberInput(classes: string[], attributes: IKeyStringPair[], id: string): InputElement {
        return new InputElement('number', classes, attributes, id);
    }

    public static CreateRadioInput(name: string, classes: string[], attributes: IKeyStringPair[], id: string): InputElement {
        const radioInput = new InputElement('radio', classes, attributes, id);
        radioInput._View.name = name;
        return radioInput;
    }

    public static CreateCheckboxInput(classes: string[], attributes: IKeyStringPair[], id: string): InputElement {
        return new InputElement('checkbox', classes, attributes, id);
    }

    public static CreateFileInput(classes: string[], attributes: IKeyStringPair[], id: string): InputElement {
        return new InputElement('file', classes, attributes, id);
    }

    public ClearValue(): void {
        if (this._View.type == 'checkbox' || this._View.type == 'radio')
            this._View.checked = false;
        else
            this._View.value = '';
    }

    public Disable(): void {
        this._View.disabled = true;
    }

    public Enable(): void {
        this._View.disabled = false;
    }
}