export interface ISelectionRangeBookmarker {
	SetCurrentRange(): void;
	ClearCurrentRange(): void;
	BookmarkedRange: Range;
	HasRange: boolean;
	ContainsSelection(node: Node): boolean;
}

export class SelectionRangeBookmarker implements ISelectionRangeBookmarker {
	private _SavedRange: Range;
	private _HasRange: boolean;
	private _AnchorNode: Node;
	private _FocusNode: Node;

	public get HasRange(): boolean { return this._HasRange; }
	public get BookmarkedRange(): Range { return this._SavedRange; }

	constructor() {
		this._HasRange = false;
		this._SavedRange = null;
		this._AnchorNode = null;
		this._FocusNode = null;
	}

	public SetCurrentRange(): void {
		const selection = window.getSelection();
		if (selection.rangeCount == 0) {
			this._HasRange = false;
			this._SavedRange = null;
			this._AnchorNode = null;
			this._FocusNode = null;
			return;
		}

		this._HasRange = true;
		this._AnchorNode = selection.anchorNode;
		this._FocusNode = selection.focusNode;
		this._SavedRange = selection.getRangeAt(0).cloneRange();
	}

	public ClearCurrentRange(): void {
		this._HasRange = false;
		this._SavedRange = null;
		this._AnchorNode = null;
		this._FocusNode = null;
	}

	public ContainsSelection(node: Node): boolean {
		return node.contains(this._AnchorNode) && node.contains(this._FocusNode);
	}
}