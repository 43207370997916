import { IRepeaterRequestContentModel, IRequestContentModel } from "./IRequestContentModel";

export class SimpleContentModel implements IRequestContentModel {
    private _Heading: string;
    public get Heading(): string { return this._Heading; }
    public set Heading(value: string) { this._Heading = value; }

    private _BodyPlain: string;
    public get BodyPlain(): string { return this._BodyPlain; }
    public set BodyPlain(value: string) { this._BodyPlain = value; }

    private _BodyWithTokens: string;
    public get BodyWithTokens(): string { return this._BodyWithTokens; }
    public set BodyWithTokens(value: string) { this._BodyWithTokens = value; }

    private _PageID: number;
    public get PageID(): number { return this._PageID; }

    constructor(heading: string, bodyPlain: string, bodyWithTokens: string, pageID: number) {
        this._Heading = heading;
        this._BodyPlain = bodyPlain;
        this._BodyWithTokens = bodyWithTokens;
        this._PageID = pageID;
    }    

    public GetRequestObject(): object {
        return { "Heading": this._Heading, "BodyPlain": this._BodyPlain, "BodyWithTokens": this._BodyWithTokens, "PageID": this._PageID };
    }
}

export class SimpleContentModel_RepeaterItem extends SimpleContentModel implements IRepeaterRequestContentModel {
    public get ImplementsIRepeaterRequestContentModel(): true { return true; }

    private _ID: number;
    public get ID(): number { return this._ID; }
    private _TemporaryID: string;
    public get TemporaryID(): string { return this._TemporaryID; }

    private _SortOrder: number;
    public get SortOrder(): number { return this._SortOrder; }
    public set SortOrder(value: number) { this._SortOrder = value; }

    constructor(heading: string, bodyPlain: string, bodyWithTokens: string, id: number, tempID: string, pageID: number) {
        super(heading, bodyPlain, bodyWithTokens, pageID);
        this._ID = id;
        this._TemporaryID = tempID;
    }

    public override GetRequestObject(): object {
        const returnObj = super.GetRequestObject();
        returnObj["ID"] = this._ID;
        returnObj["SortOrder"] = this._SortOrder;
        if (this._TemporaryID != null)
            returnObj["TemporaryID"] = this._TemporaryID;

        return returnObj;
    }
}