import { IRepeaterRequestContentModel, IRequestContentModel } from "./IRequestContentModel";
import { SimpleContentModel } from "./SimpleContentModel";

export class ImageSimpleContentModel extends SimpleContentModel implements IRequestContentModel {
    private _ImageID: number;
    public get ImageID(): number { return this._ImageID; }
    public set ImageID(value: number) { this._ImageID = value; }

    constructor(heading: string, bodyPlain: string, bodyWithTokens: string, imageID: number, pageID: number) {
        super(heading, bodyPlain, bodyWithTokens, pageID);
        this._ImageID = imageID;
    }

    public override GetRequestObject(): object {
        const returnObj = super.GetRequestObject();
        returnObj["ImageID"] = this._ImageID;

        return returnObj;
    }
}

export class ImageSimpleContentModel_RepeaterItem extends ImageSimpleContentModel implements IRepeaterRequestContentModel {
    public get ImplementsIRepeaterRequestContentModel(): true { return true; }

    private _ID: number;
    public get ID(): number { return this._ID; }
    private _TemporaryID: string;
    public get TemporaryID(): string { return this._TemporaryID; }

    private _SortOrder: number;
    public get SortOrder(): number { return this._SortOrder; }
    public set SortOrder(value: number) { this._SortOrder = value; }

    constructor(heading: string, bodyPlain: string, bodyWithTokens: string, imageID: number, id: number, tempID: string, pageID: number) {
        super(heading, bodyPlain, bodyWithTokens, imageID, pageID);
        this._ID = id;
        this._TemporaryID = tempID;
    }

    public override GetRequestObject(): object {
        const returnObj = super.GetRequestObject();
        returnObj["ID"] = this._ID;
        returnObj["SortOrder"] = this._SortOrder;
        if (this._TemporaryID != null)
            returnObj["TemporaryID"] = this._TemporaryID;

        return returnObj;
    }
}