import { ImageSimpleContentModel, ImageSimpleContentModel_RepeaterItem } from "../../Models/ImageSimpleContentModel";
import { AvailableContainerEditAction, ContainerEditingToolbar, IContainerEditingDeleteObserver, IContainerEditingRevertObserver, IContainerEditingSaveObserver } from "../../Toolbars/ContainerEditingToolbar";
import { IContentDeleteObserver, IContentDropObserver, IContentEditor, IContentEditor_RepeaterItem, IsIContentDeleteObserver, IsIContentDropObserver, RepeaterObserver } from "./IContentEditor";
import { HTMLContent, HTMLPropertyEditor } from "../PropertyContainers/HTMLPropertyEditor";
import { SelectedImageEditor } from "../PropertyContainers/SelectedImageEditor";
import { StaticContentService } from "../../Services/StaticContentService";
import { IObservable } from "../../../Utilities/IObservable";
import { IDestroyable } from "../../../Utilities/IDestroyable";
import { DivElement } from "../../../Utilities/HTML/DivElement";
import { HeadingElement } from "../../../Utilities/HTML/HeadingElement";
import { ImageElement } from "../../../Utilities/HTML/ImageElement";
import { EntityIDService, IEntityIDService } from "../../Services/EntityIDService";
import { ImageSizeSet } from "../../Models/ImageSourceSet";
import { LocalToast } from "../../../Utilities/Toast";
export abstract class ImageSimpleContentEditorBase {
    protected _ContainerElement: HTMLDivElement;
    private _ButtonContainer: HTMLDivElement;
    private _HeadingContainer: HTMLHeadingElement;
    private _BodyContainer: HTMLPropertyEditor;
    private _ImageContainer: SelectedImageEditor;    
    protected _EditingToolbar: ContainerEditingToolbar;

    private _LastSavedHeading: string;

    protected _EntityIDService: IEntityIDService;

    public get View(): HTMLDivElement { return this._ContainerElement; }

    protected _PageID: number;
    public get PageID(): number { return this._PageID; }

    private _ContainingLinkElement: HTMLAnchorElement;
    private _ContainingLink: string;

    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, imageSizeSet: ImageSizeSet, pageID: number) {
        this._ContainerElement = containerElement;
        this._ButtonContainer = containerElement.getElementsByClassName('EditingButtons')[0] as HTMLDivElement;
        this._HeadingContainer = containerElement.getElementsByClassName('Title')[0] as HTMLHeadingElement;
        if (this._ContainerElement.parentNode && this._ContainerElement.parentNode.nodeName.toLowerCase() == 'a') {
            this._ContainingLinkElement = this._ContainerElement.parentElement as HTMLAnchorElement;
            this._ContainingLink = this._ContainingLinkElement.href;
        }

        this._EntityIDService = entityIDService;
        this._BodyContainer = new HTMLPropertyEditor(containerElement.getElementsByClassName('BodyContainer')[0] as HTMLDivElement, this._EntityIDService.IDPrefixFromType + 'Body');
        this._ImageContainer = new SelectedImageEditor(containerElement.getElementsByClassName('ImageContainer')[0] as HTMLDivElement, this._EntityIDService.IDPrefixFromType + 'Image', this._EntityIDService, imageSizeSet);
        this._PageID = pageID;
        
        this.BuildEditingToolbar(actions);
        this._LastSavedHeading = this._HeadingContainer.innerHTML;
    }

    public SetToEditable(): void {
        this._ButtonContainer.classList.add('EditModeOn');
        this._HeadingContainer.setAttribute('contenteditable', 'true');
        this._BodyContainer.SetToEditable();
        this._ImageContainer.SetToEditable();
        if (this._ContainingLinkElement)
            this._ContainingLinkElement.removeAttribute('href');
    }

    public SetToReadOnly(): void {
        this._ButtonContainer.classList.remove('EditModeOn');
        this._HeadingContainer.setAttribute('contenteditable', 'false');
        this._BodyContainer.SetToReadOnly();
        this._ImageContainer.SetToReadOnly();

        if (this._ContainingLinkElement)
            this._ContainingLinkElement.href = this._ContainingLink;
    }

    public RevertToLastSaved(): void {
        this._HeadingContainer.innerHTML = this._LastSavedHeading;
        this._BodyContainer.RevertToLastSaved();
        this._ImageContainer.RevertToLastSaved();
    }

    public GetRequestObject(): ImageSimpleContentModel {
        const bodyContentToSave: HTMLContent = this._BodyContainer.CurrentHTMLContent;
        return new ImageSimpleContentModel(this._HeadingContainer.innerHTML, bodyContentToSave.PlainContent, bodyContentToSave.ContentWithTokens, this._ImageContainer.CurrentImageID ?? -1, this._PageID);
    }

    public UpdateLastSaved(): void {
        this._LastSavedHeading = this._HeadingContainer.innerHTML;
        this._BodyContainer.UpdateLastSaved();
        this._ImageContainer.UpdateLastSaved();
    }

    protected BuildEditingToolbar(actions: Array<AvailableContainerEditAction>): void {
        this._EditingToolbar = new ContainerEditingToolbar();
        this._EditingToolbar.BuildView(actions);
        this._ButtonContainer.appendChild(this._EditingToolbar.View);
    }
}

export class ImageSimpleContentEditor extends ImageSimpleContentEditorBase implements IContentEditor, IContainerEditingSaveObserver, IContainerEditingRevertObserver {
    public get ImplementsIContainerEditingSaveObserver(): true { return true; }
    public get ImplementsIContainerEditingRevertObserver(): true { return true; }
    
    private _ImageSimpleContentService: StaticContentService<ImageSimpleContentModel>;
    
    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, pageID: number) {
        super(
            containerElement,
            actions,
            new EntityIDService(containerElement.getAttribute('data-EntityType'), containerElement.getAttribute('data-EntityCategory'), parseInt(containerElement.getAttribute('data-EntityID'))),
            ImageSizeSet[containerElement.getAttribute('data-ImageSizeSet')],
            pageID
        );

        this._ImageSimpleContentService = new StaticContentService<ImageSimpleContentModel>(this._EntityIDService.EntityType, this._EntityIDService.EntityCategory);

        containerElement.removeAttribute('data-EntityType');
        containerElement.removeAttribute('data-EntityCategory');
        containerElement.removeAttribute('data-EntityID');
        containerElement.removeAttribute('data-ImageSizeSet');
    }

    public Save(): Promise<void> {
        return this._ImageSimpleContentService.Save(this.GetRequestObject())
            .then((response) => {
                if (response < 1)
                    return;

                this.UpdateLastSaved();
                LocalToast.Instance.ShowSaveContentToast();
            });
    }

    public UpdateForSave(): void {
        this.Save();
    }

    public UpdateForRevert(): void {
        this.RevertToLastSaved();
    }

    protected override BuildEditingToolbar(actions: Array<AvailableContainerEditAction>) {
        super.BuildEditingToolbar(actions);

        this._EditingToolbar.Subscribe(this);
    }
}

export class ImageSimpleContentEditor_RepeaterItem extends ImageSimpleContentEditorBase implements IContentEditor_RepeaterItem, IContainerEditingDeleteObserver, IObservable<RepeaterObserver>, IDestroyable {
    public get ImplementsIContainerEditingDeleteObserver(): true { return true; }
    public ImplementsIObservable<RepeaterObserver>(): true { return true; }

    public get ImplementsIDestroyable(): true { return true; }

    private _DeleteObservers: Set<IContentDeleteObserver>;
    private _DropObservers: Set<IContentDropObserver>;

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }
    public get EntityIDService(): IEntityIDService { return this._EntityIDService; }

    constructor(containerElement: HTMLDivElement, actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, imageSizeSet: ImageSizeSet, pageID: number) {
        super(containerElement, actions, entityIDService, imageSizeSet, pageID);

        this._DeleteObservers = new Set<IContentDeleteObserver>();
        this._DropObservers = new Set<IContentDropObserver>();
        this._IsDestroyed = false;

        this.AddEventHandlers();
    }

    public Subscribe(observer: RepeaterObserver): void {
        if (IsIContentDeleteObserver(observer))
            this._DeleteObservers.add(observer);

        if (IsIContentDropObserver(observer))
            this._DropObservers.add(observer);
    }

    public Unsubscribe(observer: RepeaterObserver): void {
        if (IsIContentDeleteObserver(observer))
            this._DeleteObservers.delete(observer);

        if (IsIContentDropObserver(observer))
            this._DropObservers.delete(observer);
    }

    public UpdateForDelete(): void {
        for (const observer of this._DeleteObservers)
            observer.UpdateForDelete(this);
    }

    public Destroy(): void {
        this._EditingToolbar.Destroy();
        this._EditingToolbar = null;

        $(this._ContainerElement).empty();
        this._ContainerElement.remove();
        this._ContainerElement = null;
        this._DeleteObservers = null;
        this._DropObservers = null;

        this._IsDestroyed = true;
    }

    public static BuildNew(actions: Array<AvailableContainerEditAction>, entityIDService: IEntityIDService, isEditing: boolean, repeatedClassMap: Map<string, string[]>, imageSizeSet: ImageSizeSet, areListGroupsFlush: boolean, pageID: number): ImageSimpleContentEditor_RepeaterItem {
        const container = DivElement.CreateDiv(['RepeatedContentContainer', 'position-relative', ...repeatedClassMap.get('content')], undefined, undefined, undefined);
        const editingButtons = DivElement.CreateDivWithClasses(['EditingButtons', 'position-absolute', 'start-0', 'bg-white', 'rounded', 'col-auto', 'p-0']);
        const imageContainer = DivElement.CreateDivWithClasses(['ImageContainer', 'container', ...repeatedClassMap.get('image')]);
        const image = ImageElement.CreateImage('/img/Default.webp', undefined, ['rounded', 'img-fluid'], undefined, undefined);
        imageContainer.AppendChild(image);

        const heading = HeadingElement.CreateH2('New Title', ['text-primary-emphasis', 'Title', 'Header'], undefined);
        heading.View.setAttribute('contenteditable', (isEditing ? 'true' : 'false'));
        const bodyContainer = DivElement.CreateDivWithClasses(['BodyContainer', ...repeatedClassMap.get('body')]);
        const paragraph = document.createElement('p');
        paragraph.className = 'Body';
        paragraph.setAttribute('contenteditable', (isEditing ? 'true' : 'false'));
        paragraph.textContent = 'New Content';
        paragraph.setAttribute('data-ListGroupsFlush', (areListGroupsFlush ? 'true' : 'false'));
        bodyContainer.View.appendChild(paragraph);

        container.Append(editingButtons, imageContainer, heading, bodyContainer);
        const editor = new ImageSimpleContentEditor_RepeaterItem(container.View, actions, entityIDService, imageSizeSet, pageID);
        if (isEditing)
            editor.SetToEditable();
        else
            editor.SetToReadOnly();

        return editor;
    }

    public override GetRequestObject(): ImageSimpleContentModel_RepeaterItem {
        const request = super.GetRequestObject();
        return new ImageSimpleContentModel_RepeaterItem(request.Heading, request.BodyPlain, request.BodyWithTokens, request.ImageID, this._EntityIDService.EntityID, this._EntityIDService.TemporaryID, this._PageID)
    }

    protected override BuildEditingToolbar(actions: Array<AvailableContainerEditAction>) {
        super.BuildEditingToolbar(actions);

        this._EditingToolbar.Subscribe(this);
    }

    private OnViewDragStartEvent = (event: DragEvent) => {
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setData('text/json', JSON.stringify({ EntityID: this._EntityIDService.EntityID, TempID: this._EntityIDService.TemporaryID }));
    }

    private OnViewDropEvent = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const data = JSON.parse(event.dataTransfer.getData('text/json'));

        let droppedEntityID: number = data['EntityID'];
        let droppedTemporaryID: string = data['TemporaryID'];

        for (let observer of this._DropObservers)
            observer.UpdateForDrop(this, droppedEntityID, droppedTemporaryID);
    }

    private AddEventHandlers(): void {
        this._ContainerElement.addEventListener('dragstart', this.OnViewDragStartEvent);
        this._ContainerElement.addEventListener('drop', this.OnViewDropEvent);
    }
}
