import { IEntityIDService } from "../ContentAdmin/Services/EntityIDService";
import { ServiceContainer } from "../ServiceContainer";
import { ModalElement } from "../Utilities/Modal";
import { IWebService } from "./IWebService";

export class ImageViewWebService {
    private readonly _WebService: IWebService;
    private static _Instance: ImageViewWebService;
    private readonly _APIPath: string;

    public static get Instance(): ImageViewWebService {
        if (!this._Instance)
            this._Instance = new ImageViewWebService();

        return this._Instance;
    }

    private constructor() {
        this._WebService = ServiceContainer.Instance.WebService;
        this._APIPath = '/ImageView/';
    }

    public GetGlobalImageWidth(fileName: string): Promise<number | void> {
        return this._WebService.GetRequest(this._APIPath + 'GetGlobalImageWidth/' + fileName)
            .then((data) => {
                return parseInt(data.toString());
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error loading images. Please try again.');
            });
    }

    public GetEntityImageWidth(fileName: string, entityIDService: IEntityIDService): Promise<number | void> {
        let methodUrl: string = this._APIPath + 'GetEntityImageWidth/' + fileName + '/' + entityIDService.EntityType + '/' + entityIDService.EntityCategory + '/' + entityIDService.EntityID.toString();
        if (!entityIDService.HasEntityID)
            methodUrl += '/' + entityIDService.TemporaryID;

        return this._WebService.GetRequest(methodUrl)
            .then((data) => {
                return parseInt(data.toString());
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error loading images. Please try again.');
            });
    }
}