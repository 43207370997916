import { IObservable } from "../../Utilities/IObservable";
import { CMSImageWebService } from "../../WebService/CMSImageWebService";
import { CMSImageGetResult } from "../../WebService/ServiceResponses/CMSImageGetResult";

export interface IGlobalCachedImageObserver {
    UpdateForInvalidatedGlobalCache(): void;
    ImplementsIGlobalCachedImageObserver: true;
}

function IsIGlobalCachedImageObserver(obj: any): obj is IGlobalCachedImageObserver {
    return obj.ImplementsIGlobalCachedImageObserver === true;
}

export interface ISingleEntityCachedImageObserver {
    UpdateForInvalidatedEntityCache(entityType: string, entityCategory: string, entityID: number): void;
    UpdateForInvalidatedTempNewEntityCache(entityType: string, entityCategory: string, tempID: string): void;
    ImplementsISingleEntityCachedImageObserver: true;
}

function IsISingleEntityCachedImageObserver(obj: any): obj is ISingleEntityCachedImageObserver {
    return obj.ImplementsISingleEntityCachedImageObserver === true;
}

type ICachedImageObserver = IGlobalCachedImageObserver | ISingleEntityCachedImageObserver;

export class ImageDataStore implements IObservable<ICachedImageObserver> {
    public ImplementsIObservable<ICachedImageObserver> (): true { return true; }

    private static _Instance: ImageDataStore;
    private _ImagePromises: Map<string, Promise<CMSImageGetResult[]>>;

    private _GlobalObservers: Set<IGlobalCachedImageObserver>;
    private _SingleEntityObservers: Set<ISingleEntityCachedImageObserver>;
    
    public static get Instance(): ImageDataStore {
        if (!this._Instance)
            this._Instance = new ImageDataStore();

        return this._Instance;
    }

    private constructor() {
        this._ImagePromises = new Map<string, Promise<CMSImageGetResult[]>>();
        this._GlobalObservers = new Set<IGlobalCachedImageObserver>();
        this._SingleEntityObservers = new Set<ISingleEntityCachedImageObserver>();
    }

    public GetGlobalImagePaths(): Promise<CMSImageGetResult[]> {
        if (!this._ImagePromises.has('Global')) {
            this._ImagePromises.set('Global', CMSImageWebService.Instance.GetGlobalImagePaths());
        }
        return this._ImagePromises.get('Global');
    }

    public GetImagePathsForEntities(entityType: string, entityCategory: string, ...entityIDs: number[]): Promise<Map<number, CMSImageGetResult[]>> {
        const toAdd = entityIDs.filter(
            (value: number) => {
                return !this._ImagePromises.has(this.GetEntityIdentifier(entityType, entityCategory, value));
            }
        );
        const failedIds = new Array<number>();
        for (const id of toAdd) {
            const identifier = this.GetEntityIdentifier(entityType, entityCategory, id)
            try {
                this._ImagePromises.set(identifier, CMSImageWebService.Instance.GetImagePathsForEntity(entityType, entityCategory, id));
            }
            catch (err) {
                this._ImagePromises.delete(identifier);
                failedIds.push(id);
            }
        }

        return Promise.all(entityIDs.map((value) => this._ImagePromises.get(this.GetEntityIdentifier(entityType, entityCategory, value))))
            .then((results) => {
                const returnMap = new Map<number, CMSImageGetResult[]>();
                for (const result of results) {
                    if (result.length > 0) {
                        if (!result[0].ImagePath.startsWith(entityType + '/' + entityCategory + '/'))
                            continue;

                        const entityID: number = parseInt(result[0].ImagePath.substring(entityType.length + 2 + entityCategory.length).split('/')[0]);
                        const filePrefix: string = entityType + '/' + entityCategory + '/' + entityID.toString() + '/';
                        result.forEach(p => p.ImagePath = p.ImagePath.replace(filePrefix, ''));
                        returnMap.set(entityID, result);
                    }
                }

                for (const id of failedIds)
                    returnMap.set(id, [CMSImageGetResult.Error]);

                return returnMap;
            });
    }

    public GetImagePathsForEntity(entityType: string, entityCategory: string, entityID: number): Promise<CMSImageGetResult[]> {
        return this.GetImagePathsForEntities(entityType, entityCategory, entityID)
            .then((map: Map<number, CMSImageGetResult[]>) => {
                return map.get(entityID);
            });
    }

    public GetTempImagePathsForNewEntities(entityType: string, entityCategory: string, ...tempIDs: string[]): Promise<Map<string, CMSImageGetResult[]>> {
        const toAdd = tempIDs.filter(
            (value: string) => {
                return !this._ImagePromises.has(this.GetTempEntityIdentifier(entityType, entityCategory, value));
            }
        );
        const failedIds = new Array<string>();
        for (const id of toAdd) {
            const identifier = this.GetTempEntityIdentifier(entityType, entityCategory, id);
            try {
                this._ImagePromises.set(identifier, CMSImageWebService.Instance.GetTempImagePathsForNewEntity(entityType, entityCategory, id));
            }
            catch (err) {
                this._ImagePromises.delete(identifier);
                failedIds.push(id);
            }
        }

        return Promise.all(tempIDs.map((value) => this._ImagePromises.get(this.GetTempEntityIdentifier(entityType, entityCategory, value))))
            .then((results) => {
                const returnMap = new Map<string, CMSImageGetResult[]>();
                for (const result of results) {
                    if (result.length > 0) {
                        if (!result[0].ImagePath.startsWith(entityType + '/' + entityCategory + '/Temp/'))
                            continue;

                        const tempID: string = result[0].ImagePath.substring(entityType.length + 7 + entityCategory.length).split('/')[0];
                        const filePrefix: string = entityType + '/' + entityCategory + '/Temp/' + tempID + '/';
                        result.forEach(p => p.ImagePath = p.ImagePath.replace(filePrefix, ''))
                        returnMap.set(tempID, result);
                    }
                }

                for (const id of failedIds)
                    returnMap.set(id, [CMSImageGetResult.Error])

                return returnMap;
            });
    }

    public GetTempImagePathsForNewEntity(entityType: string, entityCategory: string, tempID: string): Promise<CMSImageGetResult[]> {
        return this.GetTempImagePathsForNewEntities(entityType, entityCategory, tempID)
            .then((map: Map<string, CMSImageGetResult[]>) => {
                return map.get(tempID);
            });
    }

    public InvalidateForGlobal(): void {
        this._ImagePromises.delete('Global');
        for (const observer of this._GlobalObservers)
            observer.UpdateForInvalidatedGlobalCache();
    }

    public InvalidateForEntities(entityType: string, entityCategory: string, ...entityIDs: number[]): void {
        for (const id of entityIDs) {
            this._ImagePromises.delete(this.GetEntityIdentifier(entityType, entityCategory, id));
            for (const observer of this._SingleEntityObservers)
                observer.UpdateForInvalidatedEntityCache(entityType, entityCategory, id);
        }
    }

    public InvalidateForTempNewEntities(entityType: string, entityCategory: string, ...tempIDs: string[]): void {
        for (const id of tempIDs) {
            this._ImagePromises.delete(this.GetTempEntityIdentifier(entityType, entityCategory, id));
            for (const observer of this._SingleEntityObservers)
                observer.UpdateForInvalidatedTempNewEntityCache(entityType, entityCategory, id);
        }
    }

    public Subscribe(observer: ICachedImageObserver): void {
        if (IsIGlobalCachedImageObserver(observer))
            this._GlobalObservers.add(observer);

        if (IsISingleEntityCachedImageObserver(observer))
            this._SingleEntityObservers.add(observer);
    }

    public Unsubscribe(observer: ICachedImageObserver): void {
        if (IsIGlobalCachedImageObserver(observer))
            this._GlobalObservers.delete(observer);

        if (IsISingleEntityCachedImageObserver(observer))
            this._SingleEntityObservers.delete(observer);
    }

    private GetEntityIdentifier(entityType: string, entityCategory: string, entityID: number): string {
        return entityType + '$' + entityCategory + '$' + entityID.toString();
    }

    private GetTempEntityIdentifier(entityType: string, entityCategory: string, tempID: string): string {
        return entityType + '$' + entityCategory + '$-1$' + tempID;
    }
}