import { IObservable } from "../../../Utilities/IObservable";
import { ImageSizeSet, ImageSourceSet } from "../../Models/ImageSourceSet";
import { IEntityIDService } from "../../Services/EntityIDService";
import { IImageEditingObserver, ImageEditingToolbar } from "../../Toolbars/ImageEditingToolbar";
import { IComponentEditor } from "./IComponentEditor";

export interface ISelectedImageProvider {
    CurrentImageID: number;
}

export interface ISelectedImageUpdateObserver {
    HandleUpdate(imageName: string, isImageGlobal: boolean);
}

export class SelectedImageEditor implements IImageEditingObserver, IComponentEditor, ISelectedImageProvider, IObservable<ISelectedImageUpdateObserver> {
    public ImplementsIObservable<ISelectedImageUpdateObserver>(): true { return true; }

    private _Image: HTMLImageElement;
    private _CurrentImagePath: string;
    private _IsImageGlobal: boolean;
    private _CurrentImageID: number;

    public get CurrentImageID(): number { return this._CurrentImageID; }

    private _LastSavedImagePath: string;
    private _LastSavedIsImageGlobal: boolean;
    private _LastSavedImageID: number;

    private _ImageEditingToolbar: ImageEditingToolbar;
    private readonly _FilePathPrefix: string;
    private _EntityIDService: IEntityIDService;
    private _ImageSizeSet: ImageSizeSet;

    private _Observers: Set<ISelectedImageUpdateObserver>;

    constructor(imageContainer: HTMLDivElement, idPrefix: string, entityIDService: IEntityIDService, imageSizeSet: ImageSizeSet) {
        this._FilePathPrefix = window.location.origin + '/img/CMS/';
        this._Image = imageContainer.getElementsByTagName('img')[0];
        const imgSrc: string = this._Image.src;

        this._ImageEditingToolbar = new ImageEditingToolbar(idPrefix, entityIDService, this);
        this._ImageEditingToolbar.BuildView();
        this._ImageEditingToolbar.SetToReadOnly();
        imageContainer.appendChild(this._ImageEditingToolbar.View.View);
        imageContainer.classList.add('position-relative');

        this._EntityIDService = entityIDService;
        this._ImageSizeSet = imageSizeSet;

        this.AddEventHandlers();
        this._Observers = new Set<ISelectedImageUpdateObserver>();

        if (imgSrc == undefined)
            return;

        const imgPathWithFolders: string = imgSrc.substring(imgSrc.indexOf(this._FilePathPrefix) + this._FilePathPrefix.length);
        const imgPathParts: string[] = imgPathWithFolders.split('/');

        if (imgPathParts.length == 0)
            return;

        if (imgPathParts[0] == 'Global') {
            this._IsImageGlobal = true;
            this._CurrentImagePath = imgPathParts[1];
        }
        else {
            this._IsImageGlobal = false;
            if (entityIDService.HasEntityID)
                this._CurrentImagePath = imgPathParts[3]
            else
                this._CurrentImagePath = imgPathParts[4];
        }

        this._CurrentImageID = parseInt(this._Image.getAttribute('data-ImageID'));
        this._Image.removeAttribute('data-ImageID');

        this._LastSavedImagePath = this._CurrentImagePath;
        this._LastSavedIsImageGlobal = this._IsImageGlobal;
        this._LastSavedImageID = this._CurrentImageID;
    }

    public UpdateForSelectedImage(imagePath: string, isGlobal: boolean, imageID: number): void {
        this._CurrentImagePath = imagePath;
        this._CurrentImageID = imageID;
        this._IsImageGlobal = isGlobal;
        this.SetImageSourceProperties(imagePath, isGlobal, imageID);
    }

    public RevertToLastSaved(): void {
        this._CurrentImagePath = this._LastSavedImagePath;
        this._CurrentImageID = this._LastSavedImageID;
        this._IsImageGlobal = this._LastSavedIsImageGlobal;
        this.SetImageSourceProperties(this._CurrentImagePath, this._IsImageGlobal, this._CurrentImageID);
    }

    public UpdateLastSaved(): void {
        this._LastSavedImagePath = this._CurrentImagePath;
        this._LastSavedImageID = this._CurrentImageID;
        this._LastSavedIsImageGlobal = this._IsImageGlobal;
    }

    public SetToEditable(): void {
        this._ImageEditingToolbar.SetToEditable();
    }

    public SetToReadOnly(): void {
        this._ImageEditingToolbar.SetToReadOnly();
    }

    public Subscribe(observer: ISelectedImageUpdateObserver): void {
        this._Observers.add(observer);
    }

    public Unsubscribe(observer: ISelectedImageUpdateObserver): void {
        this._Observers.delete(observer);
    }

    private AddEventHandlers(): void {
        this._ImageEditingToolbar.Subscribe(this);
    }

    private GetFullImagePath(imagePath: string, isGlobal: boolean): string {
        if (isGlobal)
            return this._FilePathPrefix + 'Global/' + imagePath;
        else if (this._EntityIDService.HasEntityID)
            return this._FilePathPrefix + this._EntityIDService.EntityType + '/' + this._EntityIDService.EntityCategory + '/' + this._EntityIDService.EntityID + '/' + imagePath;
        else
            return this._FilePathPrefix + this._EntityIDService.EntityType + '/' + this._EntityIDService.EntityCategory + '/Temp/' + this._EntityIDService.TemporaryID + '/' + imagePath;
    }

    private SetImageSourceProperties(imageName: string, isGlobal: boolean, imageID: number) {
        const fullImagePath = this.GetFullImagePath(imageName, isGlobal);
        this._Image.src = fullImagePath;

        let imageSourceSetPromise: Promise<ImageSourceSet>;
        if (isGlobal)
            imageSourceSetPromise = ImageSourceSet.CreateGlobalImageForSizeSetAsync(this._ImageSizeSet, imageName, fullImagePath);
        else
            imageSourceSetPromise = ImageSourceSet.CreateEntityImageForSizeSetAsync(this._ImageSizeSet, this._EntityIDService, imageName, fullImagePath);
        imageSourceSetPromise.then((imageSourceSet: ImageSourceSet) => {
            this._Image.srcset = imageSourceSet.SourceSet;
            this._Image.sizes = imageSourceSet.Sizes;
        });   

        for (const observer of this._Observers)
            observer.HandleUpdate(imageName, isGlobal);
    }
}