export interface IRequestContentModel {
    GetRequestObject(): object;
}

export interface IRepeaterRequestContentModel {
    ID: number;
    TemporaryID: string;
    SortOrder: number;
    ImplementsIRepeaterRequestContentModel: true;
}

export function IsIRepeaterRequestContentModel(obj: any): obj is IRepeaterRequestContentModel {
    return obj.ImplementsIRepeaterRequestContentModel === true;
}