import { ImageViewWebService } from "../../WebService/ImageViewWebService";
import { IEntityIDService } from "../Services/EntityIDService";

export enum ImageSizeSet {
    Full,
    Half, 
    Third,
    Sixth,
    Undefined
}

export class ImageSourceSet {
    private _IsImageGlobal: boolean;
    private _EntityIDService: IEntityIDService;
    private _ImageName: string;
    private _ImageFullPath: string;

    private _MediaConditionsByWidth: Map<number, string>;
    private _MaxWidth: number;
    private _Sizes: string;

    public get SourceSet(): string {
        if (this._ImageName == '')
            return '/img/Default.webp ' + this._MaxWidth.toString() + 'w';

        let srcSet: string = '';

        for (const [key, value] of this._MediaConditionsByWidth)
            srcSet += this.GetImageViewSrcString(key) + ', ';

        srcSet += this._ImageFullPath + " " + this._MaxWidth.toString() + 'w'; 

        return srcSet;
    }

    public get Sizes(): string {
        return this._Sizes;
    }

    private constructor(
        isImageGlobal: boolean,
        entityIDService: IEntityIDService,
        imageName: string,
        sizeSet: ImageSizeSet,
        imageFullPath: string
    ) {
        this._IsImageGlobal = isImageGlobal;
        this._EntityIDService = entityIDService;
        this._ImageName = imageName;
        this._ImageFullPath = imageFullPath;
        this._MediaConditionsByWidth = new Map<number, string>();

        switch (sizeSet) {
            case ImageSizeSet.Full:
                this._Sizes = '100vw';
                this._MediaConditionsByWidth.set(300, "(max-width: 300px)");
                this._MediaConditionsByWidth.set(450, "(max-width: 450px)");
                this._MediaConditionsByWidth.set(600, "(max-width: 600px)");
                this._MediaConditionsByWidth.set(800, "(max-width: 800px)");
                this._MediaConditionsByWidth.set(1080, "(max-width: 1080)");
                this._MediaConditionsByWidth.set(1490, "(max-width: 1490px)");
                this._MediaConditionsByWidth.set(1810, "(max-width: 1810px)");
                this._MediaConditionsByWidth.set(2090, "(max-width: 2090px)");
                this._MediaConditionsByWidth.set(2330, "(max-width: 2330px)");
                this._MediaConditionsByWidth.set(2550, "(max-width: 2550px)");
                this._MediaConditionsByWidth.set(2750, "(max-width: 2750px)");
                this._MediaConditionsByWidth.set(2930, "(max-width: 2930px)");
                this._MediaConditionsByWidth.set(3110, "(max-width: 3110px)");
                this._MediaConditionsByWidth.set(3280, "(max-width: 3280px)");
                this._MediaConditionsByWidth.set(3440, "(max-width: 3440px)");
                this._MediaConditionsByWidth.set(3590, "(max-width: 3590px)");
                this._MediaConditionsByWidth.set(3730, "(max-width: 3730px)");
                this._MediaConditionsByWidth.set(3872, "(max-width: 3872px)");
                break;
            case ImageSizeSet.Half:
                this._Sizes = '(min-width: 1940px) 746px, (min-width: 1400px) calc(8.85vw + 576px), (min-width: 1200px) calc(22.78vw + 327px), (min-width: 1000px) calc(20vw + 300px), (min-width: 840px) 396px, 100vw';
                this._MediaConditionsByWidth.set(150, "(max-width: 300px)");
                this._MediaConditionsByWidth.set(275, "(max-width: 450px)");
                this._MediaConditionsByWidth.set(300, "(max-width: 600px)");
                this._MediaConditionsByWidth.set(400, "(max-width: 800px)");
                this._MediaConditionsByWidth.set(540, "(max-width: 1080)");
                this._MediaConditionsByWidth.set(745, "(max-width: 1490px)");
                this._MediaConditionsByWidth.set(905, "(max-width: 1810px)");
                this._MediaConditionsByWidth.set(1045, "(max-width: 2090px)");
                this._MediaConditionsByWidth.set(1165, "(max-width: 2330px)");
                this._MediaConditionsByWidth.set(1275, "(max-width: 2550px)");
                this._MediaConditionsByWidth.set(1375, "(max-width: 2750px)");
                this._MediaConditionsByWidth.set(1465, "(max-width: 2930px)");
                this._MediaConditionsByWidth.set(1555, "(max-width: 3110px)");
                this._MediaConditionsByWidth.set(1640, "(max-width: 3280px)");
                this._MediaConditionsByWidth.set(1720, "(max-width: 3440px)");
                this._MediaConditionsByWidth.set(1795, "(max-width: 3590px)");
                this._MediaConditionsByWidth.set(1865, "(max-width: 3730px)");
                this._MediaConditionsByWidth.set(1936, "(max-width: 3872px)");
                break;
            case ImageSizeSet.Third:
                this._Sizes = '(min-width: 1400px) 392px, (min-width: 1200px) 332px, (min-width: 1000px) 272px, (min-width: 780px) 192px, 100vw';
                this._MediaConditionsByWidth.set(100, "(max-width: 300px)");
                this._MediaConditionsByWidth.set(150, "(max-width: 450px)");
                this._MediaConditionsByWidth.set(200, "(max-width: 600px)");
                this._MediaConditionsByWidth.set(267, "(max-width: 800px)");
                this._MediaConditionsByWidth.set(360, "(max-width: 1080)");
                this._MediaConditionsByWidth.set(497, "(max-width: 1490px)");
                this._MediaConditionsByWidth.set(603, "(max-width: 1810px)");
                this._MediaConditionsByWidth.set(697, "(max-width: 2090px)");
                this._MediaConditionsByWidth.set(777, "(max-width: 2330px)");
                this._MediaConditionsByWidth.set(850, "(max-width: 2550px)");
                this._MediaConditionsByWidth.set(917, "(max-width: 2750px)");
                this._MediaConditionsByWidth.set(977, "(max-width: 2930px)");
                this._MediaConditionsByWidth.set(1037, "(max-width: 3110px)");
                this._MediaConditionsByWidth.set(1093, "(max-width: 3280px)");
                this._MediaConditionsByWidth.set(1147, "(max-width: 3440px)");
                this._MediaConditionsByWidth.set(1197, "(max-width: 3590px)");
                this._MediaConditionsByWidth.set(1243, "(max-width: 3730px)");
                this._MediaConditionsByWidth.set(1291, "(max-width: 3872px)");
                break;
            case ImageSizeSet.Sixth:
                this._Sizes = '(min-width: 768px) 17vw, 50vw';
                this._MediaConditionsByWidth.set(50, "(max-width: 300px)");
                this._MediaConditionsByWidth.set(75, "(max-width: 450px)");
                this._MediaConditionsByWidth.set(100, "(max-width: 600px)");
                this._MediaConditionsByWidth.set(134, "(max-width: 800px)");
                this._MediaConditionsByWidth.set(180, "(max-width: 1080)");
                this._MediaConditionsByWidth.set(249, "(max-width: 1490px)");
                this._MediaConditionsByWidth.set(302, "(max-width: 1810px)");
                this._MediaConditionsByWidth.set(349, "(max-width: 2090px)");
                this._MediaConditionsByWidth.set(389, "(max-width: 2330px)");
                this._MediaConditionsByWidth.set(425, "(max-width: 2550px)");
                this._MediaConditionsByWidth.set(459, "(max-width: 2750px)");
                this._MediaConditionsByWidth.set(489, "(max-width: 2930px)");
                this._MediaConditionsByWidth.set(519, "(max-width: 3110px)");
                this._MediaConditionsByWidth.set(547, "(max-width: 3280px)");
                this._MediaConditionsByWidth.set(574, "(max-width: 3440px)");
                this._MediaConditionsByWidth.set(599, "(max-width: 3590px)");
                this._MediaConditionsByWidth.set(622, "(max-width: 3730px)");
                this._MediaConditionsByWidth.set(646, "(max-width: 3872px)");
                break;

        }
       
        if (imageName == '') {
            this._MaxWidth = 500;
            return;
        }
    }

    private InitialiseAsync(): Promise<void> {
        if (this._IsImageGlobal) {
            return ImageViewWebService.Instance.GetGlobalImageWidth(this._ImageName)
                .then((data: number) => {
                    this._MaxWidth = data;
                });
        }
        else {
            return ImageViewWebService.Instance.GetEntityImageWidth(this._ImageName, this._EntityIDService)
                .then((data: number) => {
                    this._MaxWidth = data;
                });
        }
    }

    private GetImageViewSrcString(size: number): string {
        let returnString: string = "/ImageView/";

        if (this._IsImageGlobal) {
            returnString += "GetGlobalImage/" + this._ImageName + "/" + size.toString();
        }
        else {
            returnString += "GetEntityImage/" + this._ImageName + "/" + size.toString() + "/" + this._EntityIDService.EntityType + "/" + this._EntityIDService.EntityCategory + "/" + this._EntityIDService.EntityID.toString();

            if (!this._EntityIDService.HasEntityID)
                returnString += "/" + this._EntityIDService.TemporaryID;
        }

        returnString += " " + size.toString() + "w";

        return returnString;
    }

    public static CreateGlobalImageForSizeSetAsync(sizeSet: ImageSizeSet, imageName: string, imageFullPath: string): Promise<ImageSourceSet> {
        const sourceSet = new ImageSourceSet(true, undefined, imageName, sizeSet, imageFullPath);
        return sourceSet.InitialiseAsync()
            .then(() => sourceSet);
    }

    public static CreateEntityImageForSizeSetAsync(sizeSet: ImageSizeSet, entityIDService: IEntityIDService, imageName: string, imageFullPath: string): Promise<ImageSourceSet> {
        const sourceSet = new ImageSourceSet(false, entityIDService, imageName, sizeSet, imageFullPath);
        return sourceSet.InitialiseAsync()
            .then(() => sourceSet);
    }
}