export class CMSImageGetResult {
    private _ImageID: number;
    public get ImageID(): number { return this._ImageID; }
    public set ImageID(value: number) { this._ImageID = value; }

    private _ImagePath: string;
    public get ImagePath(): string { return this._ImagePath; }
    public set ImagePath(value: string) { this._ImagePath = value; }

    public static _Error: CMSImageGetResult;

    public static get Error(): CMSImageGetResult {
        if (!this._Error) {
            this._Error = new CMSImageGetResult();
            this._Error.ImagePath = '[[ERROR]]';
            this._Error.ImageID = -1;
        }

        return this._Error;
    }

    public static DeserialiseObject(obj: any): CMSImageGetResult {
        return Object.assign(new CMSImageGetResult(), obj);
    }
}