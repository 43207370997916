import { IObserver } from "./IObserver";

export interface IObservable<T extends IObserver> {
    ImplementsIObservable<T>(): true;
    Subscribe(s: T): void;
    Unsubscribe(s: T): void;
}

export function IsIObservable<T extends IObserver>(obj: any): obj is IObservable<T> {
    return obj.ImplementsIObservable() === true;
}