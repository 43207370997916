import { ServiceContainer } from "../ServiceContainer";
import { ModalElement } from "../Utilities/Modal";
import { FetchWebService } from "./FetchWebService";
import { IWebService } from "./IWebService";
import { CMSImageGetResult } from "./ServiceResponses/CMSImageGetResult";

export class CMSImageWebService {
    private readonly _WebService: IWebService;
    private static _Instance: CMSImageWebService;
    private readonly _APIPath: string;
    public static get Instance(): CMSImageWebService {
        if (!this._Instance)
            this._Instance = new CMSImageWebService();

        return this._Instance;
    }

    private constructor() {
        this._WebService = ServiceContainer.Instance.WebService;
        this._APIPath = '/API/CMSImage/';
    }

    public GetGlobalImagePaths(): Promise<CMSImageGetResult[]> {
        return this._WebService.GetRequest(this._APIPath + 'GetGlobalImagePaths')
            .then((data) => {
                const returnArr = Object.assign(new Array<CMSImageGetResult>(), data);
                for (let i = 0; i < returnArr.length; i++) {
                    returnArr[i] = CMSImageGetResult.DeserialiseObject(returnArr[i]);
                }
                return returnArr;
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error loading images. Please try again.');
            });
    }

    public GetImagePathsForEntity(entityType: string, entityCategory: string, entityID: number): Promise<CMSImageGetResult[]> {
        return this._WebService.GetRequest(this._APIPath + 'GetImagePathsForEntity/' + entityType + '/' + entityCategory + '/' + entityID.toString())
            .then((data) => {
                const returnArr = Object.assign(new Array<CMSImageGetResult>(), data);
                for (let i = 0; i < returnArr.length; i++) {
                    returnArr[i] = CMSImageGetResult.DeserialiseObject(returnArr[i]);
                }
                return returnArr;
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error loading images. Please try again.');
            });
    }

    public GetTempImagePathsForNewEntity(entityType: string, entityCategory: string, tempID: string): Promise<CMSImageGetResult[]> {
        return this._WebService.GetRequest(this._APIPath + 'GetImagePathsForEntity/' + entityType + '/' + entityCategory + '/-1/' + tempID)
            .then((data) => {
                const returnArr = Object.assign(new Array<CMSImageGetResult>(), data);
                for (let i = 0; i < returnArr.length; i++) {
                    returnArr[i] = CMSImageGetResult.DeserialiseObject(returnArr[i]);
                }
                return returnArr;
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error loading images. Please try again.');
            });
    }

    public DeleteGlobalImage(imagePath: string): Promise<boolean> {
        return this._WebService.DeleteRequest(this._APIPath + 'DeleteImage', {
            ImagePath: imagePath,
            IsImageGlobal: true
        })
            .then(() => {
                return true;
            })
            .catch((error: string) => {
                ModalElement.BuildAndShowErrorModal('There has been an error deleting the image. Please try again.');
                return false;
            });
    }

    public DeleteImageForEntity(imagePath: string, entityType: string, entityCategory: string, entityID: number): Promise<boolean> {
        return this._WebService.DeleteRequest(this._APIPath + 'DeleteImage', {
            ImagePath: imagePath,
            IsImageGlobal: false,
            EntityType: entityType,
            EntityCategory: entityCategory,
            EntityID: entityID
        })
            .then(() => {
                return true;
            })
            .catch((error: string) => {
                ModalElement.BuildAndShowErrorModal('There has been an error deleting the image. Please try again.');
                return false;
            });
    }

    public DeleteTempImageForNewEntity(imagePath: string, entityType: string, entityCategory: string, tempID: string): Promise<boolean> {
        return this._WebService.DeleteRequest(this._APIPath + 'DeleteImage', {
            ImagePath: imagePath,
            IsImageGlobal: false,
            EntityType: entityType,
            EntityCategory: entityCategory,
            EntityID: -1,
            TemporaryID: tempID
        })
            .then(() => {
                return true;
            })
            .catch((error: string) => {
                ModalElement.BuildAndShowErrorModal('There has been an error deleting the image. Please try again.');
                return false;
            });
    }

    private ValidateImageFileType(file: File): boolean {
        const imageTypeRegex = new RegExp('.*\\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$');
        return imageTypeRegex.test(file.name);
    }

    public UploadImage(files: FileList, isGlobal: boolean, entityType: string, entityCategory: string, entityID: number): Promise<boolean> {
        const formData = new FormData();
        for (const file of files) {
            if (!this.ValidateImageFileType(file)) {
                ModalElement.BuildAndShowErrorModal('Not all files are the correct image file type. Please try again.');
                return;
            }

            formData.append('Images', file);
        }

        let method: string = this._APIPath + 'UploadImage/' + isGlobal;
        if (!isGlobal)
            method += '/' + entityType + '/' + entityCategory + '/' + entityID.toString();
        return new FetchWebService().PostFormRequest(method, formData)
            .then((success) => {
                if (!success)
                    ModalElement.BuildAndShowErrorModal('There has been an error uploading the image. Please try again.');
                return success;
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error uploading the image. Please try again.');
                return false;
            });
    }

    public UploadImageForNewEntity(files: FileList, entityType: string, entityCategory: string, tempID: string): Promise<boolean> {
        const formData = new FormData();
        for (const file of files) {
            if (!this.ValidateImageFileType(file)) {
                ModalElement.BuildAndShowErrorModal('Not all files are the correct image file type. Please try again.');
                return;
            }
            formData.append('Images', file);
        }

        let method: string = this._APIPath + 'UploadImage/false/' + entityType + '/' + entityCategory + '/-1/' + tempID;
        return new FetchWebService().PostFormRequest(method, formData)
            .then((success) => {
                if (!success)
                    ModalElement.BuildAndShowErrorModal('There has been an error uploading the image. Please try again.');
                return success;
            })
            .catch((error) => {
                ModalElement.BuildAndShowErrorModal('There has been an error uploading the image. Please try again.');
                return false;
            });
    }
}