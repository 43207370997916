import { IDestroyable } from "../IDestroyable";
import { ElementBase } from "./ElementBase";
import { IconName } from "./IconName";

export class IconElement implements IDestroyable {
    public get ImplementsIDestroyable(): true { return true; }

    protected _View: SVGSVGElement;
    public get View(): SVGSVGElement { return this._View; }

    private _$View: JQuery<SVGSVGElement>;
    public get $View(): JQuery<SVGSVGElement> {
        if (this._$View == null)
            this._$View = $(this.View);

        return this._$View;
    }

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }

    private constructor(iconName: IconName, width: string, height: string) {
        this._IsDestroyed = false;

        this._View = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this._View.classList.add('bi');
        this._View.classList.add('bi-' + iconName);
        this._View.setAttribute('width', width);
        this._View.setAttribute('height', height);
        this._View.setAttribute('fill', 'currentColor');
        this._View.setAttribute('viewBox', '0 0 16 16');

        switch (iconName) {
            case IconName["file-earmark-plus"]:
                const filePath1El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                filePath1El.setAttribute('d', 'M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5');
                const filePath2El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                filePath2El.setAttribute('d', 'M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z');

                this._View.append(filePath1El, filePath2El);
                break;
            case IconName["eraser-fill"]:
                const erasePathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                erasePathEl.setAttribute('d', 'M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z');
                
                this._View.append(erasePathEl);
                break;
            case IconName["arrow-counterclockwise"]:
                const arrowPath1El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                arrowPath1El.setAttribute('fill-rule', 'evenodd');
                arrowPath1El.setAttribute('d', 'M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z');

                const arrowPath2El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                arrowPath2El.setAttribute('d', 'M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466');

                this._View.append(arrowPath1El, arrowPath2El);
                break;
            case IconName["floppy-fill"]:
                const floppyPath1El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                floppyPath1El.setAttribute('d', 'M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z');

                const floppyPath2El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                floppyPath2El.setAttribute('d', 'M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z');

                this._View.append(floppyPath1El, floppyPath2El);
                break;
            case IconName["x-lg"]:
                const xPathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                xPathEl.setAttribute('d', 'M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z');

                this._View.appendChild(xPathEl);
                break;
            case IconName.at:
                const atPathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                atPathEl.setAttribute('d', 'M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914');

                this._View.appendChild(atPathEl);
                break;
            case IconName["envelope-plus"]:
                const envPath1El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                envPath1El.setAttribute('d', 'M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z');

                const envPath2El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                envPath2El.setAttribute('d', 'M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5');

                this._View.append(envPath1El, envPath2El);
                break;
            case IconName["list-ul"]:
                const listPathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                listPathEl.setAttribute('fill-rule', 'evenodd');
                listPathEl.setAttribute('d', 'M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2');

                this._View.appendChild(listPathEl);
                break;
            case IconName["type-bold"]:
                const boldPathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                boldPathEl.setAttribute('d', 'M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z');

                this._View.appendChild(boldPathEl);
                break;
            case IconName["pencil-fill"]:
                const pencilPathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                pencilPathEl.setAttribute('d', 'M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z');

                this._View.appendChild(pencilPathEl);
                break;
        }
    }

    public static CreateNewIcon(iconName: IconName, width: string = '16', height = '16'): IconElement {
        return new IconElement(iconName, width, height);
    }

    public Destroy(): void {
        if (this._$View) {
            this._$View.remove();
            this._$View = null;
        }
        this._View.remove();
        this._View = null;
        this._IsDestroyed = true;
    }

    public Hide(): void {
        this._View.style.visibility = 'hidden';
    }

    public Show(): void {
        this._View.style.visibility = 'visible';
    }

    public AddClass(...classNames: string[]): void {
        this._View.classList.add(...classNames);
    }

    public RemoveClass(...classNames: string[]): void {
        this._View.classList.remove(...classNames);
    }
} 
