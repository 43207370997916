import { ContentContainerCreator } from "./ContentContainers/ContentContainerCreator";
import { IContentViewer } from "./ContentContainers/Viewers/IContentViewer";

export class PageContentViewer {
    private _ContentContainers: Array<IContentViewer>;
    public constructor() {
        this._ContentContainers = new Array<IContentViewer>();

        for (let element of document.getElementsByClassName('ContentContainer')) {
            if (!(element instanceof HTMLDivElement))
                throw new Error('ContentContainer type is not supported.');

            this._ContentContainers.push(ContentContainerCreator.Instance.CreateViewerForContent(element));
        }
    }

    public PrepareForView(): void {
        for (const viewer of this._ContentContainers)
            viewer.PrepareContentForView();
    }
}