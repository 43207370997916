import { FetchWebService } from "./WebService/FetchWebService";
import { IWebService } from "./WebService/IWebService";

export class ServiceContainer {
    private _WebService: IWebService = new FetchWebService();
    public get WebService(): IWebService { return this._WebService; }

    private static _Instance: ServiceContainer;
    public static get Instance(): ServiceContainer {
        if (!this._Instance)
            this._Instance = new ServiceContainer();

        return this._Instance;
    }

    private constructor() {

    }
}