//import * as bootstrap from 'bootstrap';
import Modal from 'bootstrap/js/dist/modal';
import { ButtonElement } from "./HTML/ButtonElement";
import { DivElement } from "./HTML/DivElement";
import { ElementBase } from "./HTML/ElementBase";
import { HeadingElement } from "./HTML/HeadingElement";
import { IDestroyable } from "./IDestroyable";
import { IKeyStringPair } from "./IStringStringPair";
import { IconElement } from './HTML/IconElement';
import { IconName } from './HTML/IconName';

export class ModalElement implements IDestroyable {
    public get ImplementsIDestroyable(): true { return true; }

    private _ModalDiv: DivElement;
    public get View(): HTMLDivElement { return this._ModalDiv.View; }

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }

    protected _IDPrefix: string;
    private _Title: string;
    private _CloseButton: ButtonElement;

    private _ModalInstance: bootstrap.Modal;

    private _DestroyOnClose: boolean;

    constructor(idPrefix: string, title: string, destroyOnClose: boolean) {
        this._IsDestroyed = false;
        this._IDPrefix = idPrefix;
        this._Title = title;
        this._DestroyOnClose = destroyOnClose;
    }

    public Show(): void {
        this._ModalInstance.show();
    }

    public Hide(): void {
        this._ModalInstance.hide();
    }

    public Destroy(): void {
        this._ModalInstance.dispose();
        this._ModalInstance = null;
        this._ModalDiv.Destroy();
        this._ModalDiv = null;

        this._IsDestroyed = true;
    }

    public BuildAndAttach(body: string | ElementBase<HTMLElement>, footerButtons: ButtonElement[]): void {
        if (this._IDPrefix == null || this._IDPrefix == '')
            this._IDPrefix = Math.random().toString();

        const viewAttributes: IKeyStringPair[] = new Array<IKeyStringPair>();
        viewAttributes.push({ Key: 'role', Value: 'dialog' });
        viewAttributes.push({ Key: 'aria-labelledBy', Value: this._IDPrefix + 'ModalLabel' })

        this._ModalDiv = DivElement.CreateDiv(['modal', 'fade'], viewAttributes, undefined, -1);
        this._ModalDiv.View.ariaHidden = 'true';

        const dialog: DivElement = DivElement.CreateDiv(['modal-dialog'], [{ Key: 'role', Value: 'document' }], undefined, undefined);
        const content: DivElement = DivElement.CreateDivWithClass('modal-content');
        const header: DivElement = DivElement.CreateDivWithClasses(['modal-header', 'bg-secondary']);
        const heading: HeadingElement = HeadingElement.CreateH5(this._Title, ['modal-title', 'fw-bold', 'text-dark'], this._IDPrefix + 'ModalLabel');

        const closeAttributes: IKeyStringPair[] = new Array<IKeyStringPair>();
        closeAttributes.push({ Key: 'data-dismiss', Value: 'modal' });
        this._CloseButton = ButtonElement.CreateEmptyButton(['close', 'btn', 'btn-primary'], closeAttributes);
        this._CloseButton.View.ariaLabel = 'Close';

        const closeSymbol: IconElement = IconElement.CreateNewIcon(IconName['x-lg']);
        closeSymbol.View.ariaHidden = 'true';
        this._CloseButton.AppendChild(closeSymbol);

        header.Append(...[heading, this._CloseButton]);

        const bodyElement: DivElement = DivElement.CreateDivWithClass('modal-body');
        if (body instanceof ElementBase)
            bodyElement.AppendChild(body);
        else
            bodyElement.View.innerText = body;

        const footer: DivElement = DivElement.CreateDivWithClass('modal-footer');
        footer.Append(...footerButtons);

        content.Append(...[header, bodyElement, footer]);
        dialog.AppendChild(content);

        this._ModalDiv.AppendChild(dialog);
        document.body.appendChild(this._ModalDiv.View);
        this._ModalInstance = new Modal(this._ModalDiv.View);

        this.AddEventHandlers();
    }

    protected AddEventHandlers(): void {
        this._CloseButton.AddClickEventHandler(this.OnCloseButtonClick);
        this._ModalDiv.View.removeEventListener('hidden.bs.modal', this.OnClosedEvent)
        this._ModalDiv.View.addEventListener('hidden.bs.modal', this.OnClosedEvent);
    }

    private OnCloseButtonClick = () => {
        this.Hide();
    }

    private OnClosedEvent = () => {
        if (this._DestroyOnClose)
            this.Destroy();
    }

    public static BuildAndShowErrorModal(errorMessage: string) {
        const modal = new ModalElement('GlobalErrorModal', 'Error', true);
        const okBtn = ButtonElement.CreateButtonForText(['btn', 'btn-primary'], undefined, 'Ok');
        modal.BuildAndAttach(errorMessage, [okBtn]);

        const onOkBtnClick = (event) => {
            modal.Hide();
        }

        okBtn.AddClickEventHandler(onOkBtnClick);
        modal.Show();
    }
}