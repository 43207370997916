import { ButtonElement } from "../../Utilities/HTML/ButtonElement";
import { IObservable } from "../../Utilities/IObservable"; 
import { IDestroyable } from "../../Utilities/IDestroyable";
import { IconElement } from "../../Utilities/HTML/IconElement";
import { IconName } from "../../Utilities/HTML/IconName";

type IContainerEditingObserver = IContainerEditingDeleteObserver | IContainerEditingRevertObserver | IContainerEditingSaveObserver | IContainerEditingAddObserver;
export interface IContainerEditingSaveObserver {
    UpdateForSave(): void;
    ImplementsIContainerEditingSaveObserver: true;
}

function IsIContainerEditingSaveObserver(obj: any): obj is IContainerEditingSaveObserver {
    return obj.ImplementsIContainerEditingSaveObserver === true;
}

export interface IContainerEditingRevertObserver {
    UpdateForRevert(): void;
    ImplementsIContainerEditingRevertObserver: true;
}

function IsIContainerEditingRevertObserver(obj: any): obj is IContainerEditingRevertObserver {
    return obj.ImplementsIContainerEditingRevertObserver === true;
}

export interface IContainerEditingDeleteObserver {
    UpdateForDelete(): void;
    ImplementsIContainerEditingDeleteObserver: true;
}

function IsIContainerEditingDeleteObserver(obj: any): obj is IContainerEditingDeleteObserver {
    return obj.ImplementsIContainerEditingDeleteObserver === true;
}

export interface IContainerEditingAddObserver {
    UpdateForAdd(): void;
    ImplementsIContainerEditingAddObserver: true;
}

function IsIContainerEditingAddObserver(obj: any): obj is IContainerEditingAddObserver {
    return obj.ImplementsIContainerEditingAddObserver === true;
}

export class ContainerEditingToolbar implements IObservable<IContainerEditingObserver>, IDestroyable {
    public ImplementsIObservable<IContainerEditingObserver>(): true { return true; }
    public get ImplementsIDestroyable(): true { return true; }

    private _View: HTMLUListElement;
    public get View(): HTMLUListElement { return this._View; }

    private _SaveObservers: Set<IContainerEditingSaveObserver>;
    private _RevertObservers: Set<IContainerEditingRevertObserver>;
    private _DeleteObservers: Set<IContainerEditingDeleteObserver>;
    private _AddObservers: Set<IContainerEditingAddObserver>;

    protected _Buttons: Map<AvailableContainerEditAction, ButtonElement>;

    private _IsDestroyed: boolean;
    public get IsDestroyed(): boolean { return this._IsDestroyed; }

    constructor() {
        this._SaveObservers = new Set<IContainerEditingSaveObserver>();
        this._DeleteObservers = new Set<IContainerEditingDeleteObserver>();
        this._RevertObservers = new Set<IContainerEditingRevertObserver>();
        this._AddObservers = new Set<IContainerEditingAddObserver>();
        this._Buttons = new Map < AvailableContainerEditAction, ButtonElement > ();
        this._IsDestroyed = false;
    }

    public BuildView(actions: Array<AvailableContainerEditAction>): HTMLMenuElement {
        this._View = document.createElement('ul');
        this._View.className = 'btn-group p-0 m-0';

        for (const action of actions) {
            const button = ButtonElement.CreateEmptyButton(['btn'], [{ Key: 'aria-label', Value: action.toString() }, { Key: 'title', Value: action.toString() }]);

            let iconName: IconName;
            switch (action) {
                case AvailableContainerEditAction.Add:
                    iconName = IconName["file-earmark-plus"];
                    break;
                case AvailableContainerEditAction.Delete:
                    iconName = IconName["eraser-fill"];
                    break;
                case AvailableContainerEditAction.Revert:
                    iconName = IconName["arrow-counterclockwise"];
                    break;
                case AvailableContainerEditAction.Save:
                    iconName = IconName["floppy-fill"];
                    break;
            }

            const icon = IconElement.CreateNewIcon(iconName);
            button.AppendChild(icon);

            const listItem = document.createElement('li');
            listItem.className = 'btn btn-outline-primary';
            listItem.appendChild(button.View);

            this._View.appendChild(listItem);
            button.AddClickEventHandler<{ action: AvailableContainerEditAction }>(this.OnUpdateButtonClick, { action: action });
            this._Buttons.set(action, button);
        }

        return this._View;
    }

    public Subscribe(observer: IContainerEditingObserver): void {
        if (IsIContainerEditingDeleteObserver(observer))
            this._DeleteObservers.add(observer);

        if (IsIContainerEditingSaveObserver(observer))
            this._SaveObservers.add(observer);

        if (IsIContainerEditingRevertObserver(observer))
            this._RevertObservers.add(observer);

        if (IsIContainerEditingAddObserver(observer))
            this._AddObservers.add(observer);
    }

    public Unsubscribe(observer: IContainerEditingObserver): void {
        if (IsIContainerEditingDeleteObserver(observer))
            this._DeleteObservers.delete(observer);

        if (IsIContainerEditingSaveObserver(observer))
            this._SaveObservers.delete(observer);

        if (IsIContainerEditingRevertObserver(observer))
            this._RevertObservers.delete(observer);

        if (IsIContainerEditingAddObserver(observer))
            this._AddObservers.delete(observer);
    }

    public NotifyUpdate(action: AvailableContainerEditAction): void {
        switch (action) {
            case AvailableContainerEditAction.Delete:
                for (let observer of this._DeleteObservers)
                    observer.UpdateForDelete();
                break;
            case AvailableContainerEditAction.Revert:
                for (let observer of this._RevertObservers)
                    observer.UpdateForRevert();
                break;
            case AvailableContainerEditAction.Save:
                for (let observer of this._SaveObservers)
                    observer.UpdateForSave();
                break;
            case AvailableContainerEditAction.Add:
                for (let observer of this._AddObservers)
                    observer.UpdateForAdd();
                break;
        }
    }

    public Destroy(): void {
        for (const buttonMap of this._Buttons) {
            buttonMap[1].RemoveClickEventHandler(this.OnUpdateButtonClick);
            buttonMap[1].Remove();
        }
        this._Buttons = null;
        this._DeleteObservers = null;
        this._RevertObservers = null;
        this._SaveObservers = null;
        this._AddObservers = null;

        this._View.remove();
        this._View = null;
            
        this._IsDestroyed = true;
    }

    private OnUpdateButtonClick = (event: JQuery.ClickEvent<HTMLElement, { action: AvailableContainerEditAction }>) => {
        this.NotifyUpdate(event.data.action);
    }
}

export enum AvailableContainerEditAction {
    Save = 'SAVE',
    Revert = 'REVERT',
    Delete = 'DELETE',
    Add = 'ADD'
}