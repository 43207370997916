import { IContentEditor, IContentEditor_RepeaterItem, IExistingEditorInitialiseProperties, IImageSimpleEditorInitialiseProperties, IImageSimpleRepeaterEditorInitialiseProperties, IRepeaterInitialiseProperties, ISimpleEditorInitialiseProperties, ISimpleRepeaterEditorInitialiseProperties } from "./Editors/IContentEditor";
import { ImageSimpleContentEditor, ImageSimpleContentEditor_RepeaterItem } from "./Editors/ImageSimpleContentEditor";
import { RepeaterContentEditor } from "./Editors/RepeaterContentEditor";
import { SimpleContentEditor, SimpleContentEditor_RepeaterItem } from "./Editors/SimpleContentEditor";
import { ContentViewer } from "./Viewers/ContentViewer";
import { IContentViewer } from "./Viewers/IContentViewer";

export enum EditorType { 'ImageSimpleContent','SimpleStaticContent','RepeaterStaticContent'}
export type SingleInitialiseProperties<T extends EditorType> = T extends EditorType.ImageSimpleContent ? IImageSimpleEditorInitialiseProperties :
    T extends EditorType.SimpleStaticContent ? ISimpleEditorInitialiseProperties :
    T extends EditorType.RepeaterStaticContent ? IRepeaterInitialiseProperties : never;

export type RepeaterInitialiseProperties<T extends EditorType> = T extends EditorType.ImageSimpleContent ? IImageSimpleRepeaterEditorInitialiseProperties :
    T extends EditorType.SimpleStaticContent ? ISimpleRepeaterEditorInitialiseProperties : IExistingEditorInitialiseProperties;

export class ContentContainerCreator {
    private static _Instance: ContentContainerCreator;

    public static get Instance(): ContentContainerCreator {
        if (!this._Instance)
            this._Instance = new ContentContainerCreator();

        return this._Instance;
    }

    private constructor() {

    }

    public CreateEditorForContentType<T extends EditorType>(contentType: T, initialiseProperties: SingleInitialiseProperties<T> & IExistingEditorInitialiseProperties): IContentEditor {
        switch (contentType) {
            case EditorType.ImageSimpleContent:
                const imgProps = <SingleInitialiseProperties<EditorType.ImageSimpleContent>>initialiseProperties;
                return new ImageSimpleContentEditor(initialiseProperties.ContentContainer, imgProps.Actions, imgProps.PageID);
            case EditorType.SimpleStaticContent:
                const simpleProps = <SingleInitialiseProperties<EditorType.SimpleStaticContent>>initialiseProperties;
                return new SimpleContentEditor(initialiseProperties.ContentContainer, simpleProps.Actions, simpleProps.PageID);
            case EditorType.RepeaterStaticContent:
                const repeaterProps = <SingleInitialiseProperties<EditorType.RepeaterStaticContent>>initialiseProperties
                return new RepeaterContentEditor(initialiseProperties.ContentContainer, repeaterProps.PageID);
            default:
                throw new Error('No create method for the supplied contentType');
        }
    }

    public CreateRepeaterEditorForContentType<T extends EditorType>(contentType: T, initialiseProperties: RepeaterInitialiseProperties<T> & IExistingEditorInitialiseProperties): IContentEditor_RepeaterItem {
        switch (contentType) {
            case EditorType.ImageSimpleContent:
                const imgProps = <RepeaterInitialiseProperties<EditorType.ImageSimpleContent>>initialiseProperties;
                return new ImageSimpleContentEditor_RepeaterItem(initialiseProperties.ContentContainer, imgProps.Actions, imgProps.EntityIDService, imgProps.ImageSizeSet, imgProps.PageID);
            case EditorType.SimpleStaticContent:
                const simpleProps = <RepeaterInitialiseProperties<EditorType.SimpleStaticContent>>initialiseProperties;
                return new SimpleContentEditor_RepeaterItem(initialiseProperties.ContentContainer, simpleProps.Actions, simpleProps.EntityIDService, simpleProps.PageID);
            default:
                throw new Error('No create method for the supplied contentType');
        }
    }

    public CreateViewerForContent(contentContainer: HTMLDivElement): IContentViewer {
        return new ContentViewer(contentContainer);
    }

    public BuildNewContentForRepeater<T extends EditorType>(contentType: T, isEditing: boolean, repeatedClassMap: Map<string, string[]>, initialiseProperties: RepeaterInitialiseProperties<T>): IContentEditor_RepeaterItem {
        switch (contentType) {
            case EditorType.ImageSimpleContent:
                const imgProps = <RepeaterInitialiseProperties<EditorType.ImageSimpleContent>>initialiseProperties;
                return ImageSimpleContentEditor_RepeaterItem.BuildNew(imgProps.Actions, imgProps.EntityIDService, isEditing, repeatedClassMap, imgProps.ImageSizeSet, imgProps.AreListGroupsFlush, imgProps.PageID);
            case EditorType.SimpleStaticContent:
                const simpleProps = <RepeaterInitialiseProperties<EditorType.SimpleStaticContent>>initialiseProperties;
                return SimpleContentEditor_RepeaterItem.BuildNew(simpleProps.Actions, simpleProps.EntityIDService, isEditing, repeatedClassMap, simpleProps.AreListGroupsFlush, simpleProps.PageID);
            default:
                throw new Error('No method for the supplied contentType');
        }
    }
} 